import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';


interface Props{
    cardData : {
        URL: string;
        desc: string;
        routeLink : string;
    }[]
}
const Cards = ({cardData} : Props) => {
    return (
    <div className='page-container-Cards'>
        {cardData.map((card, index) => (
            <Link to = {card.routeLink} onClick={scrollToTop}key = {index}>
                <div className='grid-card'>
                    <img src = {card.URL} alt = {card.URL} className={'image-cards'+index}/>
                    <p>{card.desc}</p>
                </div>
            </Link>
        ))}
    </div>
    )
}

export default Cards