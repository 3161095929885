import React from 'react';
import Breadcrumb from '../Breadcrumb';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataTechnicalWriting } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const TechWriting = () => {
  return (
    <div className="page-container-tech-writing">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>Technical Writing</h1>
          </div>
            <Link to="/technical-writing" className='no-decoration'><Breadcrumb text={"Technical Writing"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataTechnicalWriting} />
        <FooterServices />
          <Footer />
    </div>
  )
}

export default TechWriting