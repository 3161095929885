import React, { useState } from 'react';
import './style.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles

interface CardProps {
  index: number;
  frontImage: string;
  backImage: string;
}
const TeamCard: React.FC<CardProps> = ({
  index,
  frontImage,
  backImage,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleModal = (isMouseEnter: boolean): void => {
    if (isMouseEnter) {
      setIsFlipped(false);
    } else {
      setIsFlipped(false);
    }
  };
  return (
    <div
      className={`team-card ${isFlipped ? 'flipped' : ''}`}
      onMouseEnter={() => handleModal(true)}
      onMouseLeave={() => handleModal(false)}
    >
      <div className="team-card-face team-card-front ">
        <LazyLoadImage
          src={frontImage}
          alt={`Front of card ${index}`}
        />
      </div>
      <div className="team-card-face team-card-back">
        <img src={backImage} alt='Wingman Partners' />
      </div>
    </div>
  );
};

export default TeamCard;



