import React from 'react';
import './style.scss';
import { techspaces } from 'src/shared/utils/string';
import StoryDescription from '../StoryDescription';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const TechEnabledSpaces = () => {


  return (
    <div className='page-container-techspaces'>
      <div className='image-container'>
        <h1 className='heading'>Tech-Enabled Spaces</h1>
        <p className='breadcrumb'>
          <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to= {pageRoutes.SuccessStories} onClick={scrollToTop}>Success Stories</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link fw-light' to={pageRoutes.TechSpaces} onClick={scrollToTop}>Tech-Enabled Spaces</Link>

        </p>
        <div className='red-bg-box'></div>
      </div>
      <StoryDescription storyData={techspaces} />
      <Footer />
    </div>
  )
}

export default TechEnabledSpaces