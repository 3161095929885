import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { lightboxData, lightboxData1 } from "src/shared/utils/string";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Button from "src/components/button";

const CarouselAlbum = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        See All
      </Button>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={lightboxData}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        styles={{
          thumbnailsContainer: {
            backdropFilter: "blur(6px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          },
          container: {
            backdropFilter: "blur(6px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          }
        }}
      />
    </>
  );
};
export default CarouselAlbum;

export const CarouselAlbum1 = () => {
    const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        See All
      </Button>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={lightboxData1}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        styles={{
          thumbnailsContainer: {
            backdropFilter: "blur(6px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          },
          container: {
            backdropFilter: "blur(6px)",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          }
        }}
      />
    </>
  );
}