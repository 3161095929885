import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  IMAGES,
  qufreeTechStack,
  scrollToTop,
  carouselTechOptions,
} from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import {
  cardDataQufree,
  qufreeCard,
  cardDataQufre,
  cardVendor,
} from "src/shared/utils/string";
import { images } from "src/shared/utils/appConstant";
import "animate.css";
import "animate.css/animate.min.css";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";
import HomePageFooter from "src/views/home/components/Footer";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface PropsQufree {
  cardQufree: {
    card: string;
    heading: string;
    subPoints: string[];
  }[];
}
interface PropQu {
  quCard: {
    pin: string;
    image: string;
    heading: string;
    subPoints: string[];
  }[];
}
// eslint-disable-next-line
interface QufreeTech {
  qufreeLast: {
    name: string;
    url: string;
  }[];
}
const QufreeLastCard = ({ quCard }: PropQu) => {
  return (
    <div className="main-container-qufree" id="card-solution">
      {quCard.map((value, index) => (
        <div className="card-qufree" key={index}>
          <img src={value.pin} alt="container-icons" className={"logo"} />
          <img src={value.image} alt="container-icons" className={"app"} />
          <div className="qufree-point">
            <p className="qu-card-p">
              {value.heading}{" "}
              <span className="qu-card-subpoints">{value.subPoints}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const CardInsideQufree = ({ cardQufree }: PropsQufree) => {
  return (
    <div className="main-container-solutions-qufree row" id="card-solutions">
      {cardQufree.map((value, index) => (
        <>
          <div className="card-solutions-qufree" key={index}>
            <div
              className={`vert-line-solutions-qufree vert-qufree-${index}`}
            ></div>
            <div className="solution-card-heading-qufree">
              <p>{value.card}</p>
              <h5>{value.heading}</h5>
              {value.subPoints.map((points, subIndex) => (
                <p
                  key={subIndex}
                  dangerouslySetInnerHTML={{ __html: points }}
                />
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
const CardsecondQufree = ({ cardQufree }: PropsQufree) => {
  return (
    <div className="main-container-solutions-qufree row" id="card-solutions">
      {cardQufree.map((value, index) => (
        <>
          <div className="card-solutions-qufree" key={index}>
            <div
              className={`vert-line-solutions-second vert-second-${index}`}
            ></div>
            <div className="solution-card-heading-qufree">
              <p>{value.card}</p>
              <h5>{value.heading}</h5>
              {value.subPoints.map((points, subIndex) => (
                <p
                  key={subIndex}
                  dangerouslySetInnerHTML={{ __html: points }}
                />
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
const Solutions = () => {
  return (
    <div className="qufree-container">
      <div className="qufree-image-container image-container position-rel">
        <div className="qufree-heading-desc display-none drop-in-2">
          <p>
            Your ultimate stop for seamless physical shopping solutions.
            Effortless browsing and hassle-free transactions redefine
            convenience at every step.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link
            to={pageRoutes.Home}
            onClick={scrollToTop}
            className="breadcrumb-qufree"
          >
            Home
          </Link>
          <span className="breadcrumb-separator breadcrumb-qufree"> / </span>
          <Link
            to={pageRoutes.Solutions}
            onClick={scrollToTop}
            className="breadcrumb-qufree"
          >
            Solutions
          </Link>
          <span className="breadcrumb-separator breadcrumb-qufree"> / </span>
          <p className="fw-light">
            <Link
              to={pageRoutes.Qufree}
              onClick={scrollToTop}
              className="breadcrumb-qufree"
            >
              Qufree
            </Link>
          </p>
        </div>
      </div>
      <div className="qufree-content-container ">
        <div className="qufree-upper-section-heading drop-in-2">
          <img src={images.Qufree} alt="icon" />
        </div>
        <div className="qufree-content-heading">
          <p>
            Empowering your shopping experience, delivering freedom and
            flexibility at your fingertips.
          </p>
        </div>

        <div className="qufree-main-content row ">
          <div className="qufree-up-content row">
            <div className="cv-mob-bg position-abs display-toggle">
              <img
                className="position-abs"
                src={IMAGES.DOT_CIRCLES}
                alt="dot"
              />
            </div>
            <div className="qufree-para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <h1>
                What is <br /> QuFree ?
              </h1>
              <div className="dashboard-qufree-mob display-toggle">
                <img src={images.QUFREE_SCREEN} alt="dashboard-qufree" />
              </div>
              <p>
                The ultimate solution to eliminate the frustration of waiting in
                long queues or dealing with delays due to crowded kiosks and
                shops. Qufree transforms the way you engage with a diverse range
                of purchases daily, providing a user-friendly platform for
                effortless browsing, ordering, receive timely notifications, and
                seamless transactions across a spectrum of products and services
                around you.
              </p>
            </div>
            <div className="qufree-mid-content col-md-6 animate__animated animate__fadeInRight animate__delay-1s">
              <div className="dashboard-qufree-img display-none">
                <img src={images.QUFREE_SCREEN} alt="qufree-app" />
              </div>
            </div>
          </div>

          <div className="qufree-center-content">
            <h2>
              QuFree is here to{" "}
              <span className="color-text-qufree">Revolutionize</span> your{" "}
              <span className="color-text-qufree">Shopping</span> Experience {" "}
            </h2>
          </div>
          <div className="card-solution-qufree">
            <CardInsideQufree cardQufree={cardDataQufree} />
          </div>
          <div className="qufree-role row">
            <h3>
              Join now with these{" "}
              <span className="color-text-qufree">easy</span> Steps{" "}
            </h3>
            <p>
              Don't miss out! Join in moments with our quick and easy sign-up
              process.
            </p>
            <div className="qufree-para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <QufreeLastCard quCard={qufreeCard} />
            </div>
          </div>
          <div className="qufree-role row">
            <h3>
              {" "}
              Are you a<span className="color-text-qufree"> vendor ? </span>
            </h3>
            <div className="heading">
              <h3>Don’t worry QuFree has special vendor features</h3>
            </div>
            <p>
              Customized for individuals with physical stores, Qufree is the
              perfect solution for those facing challenges in presenting their
              full range of products to customers. Vendors can register easily,
              and users can place orders hassle-free, making it a win-win
              platform.  
            </p>
          </div>
          <div className="card-solution-qufree">
            <CardsecondQufree cardQufree={cardDataQufre} />
          </div>
          <div className="qufree-role row">
            <h3>
              {" "}
              Quick <span className="color-text-qufree"> vendor </span>{" "}
              onboarding{" "}
            </h3>
            <p>
              Don't miss out on becoming our valued partners! Join in moments
              with our streamlined sign-up process for a world of opportunities.
            </p>
            <div className="qufree-para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <QufreeLastCard quCard={cardVendor} />
            </div>
          </div>
          <div className="qufree-techstack row">
            <h4>
              {" "}
              <span className="color-text-qufree"> Technology </span> Stack{" "}
            </h4>
            <p>
              QuFree leverages cutting-edge technology for seamless functioning.
            </p>
          </div>
          <div className="lower-div">
            <div className="technologies">
              <div className="images-Tech">
                {qufreeTechStack.map((qufreeLast, index) => (
                  <div key={index} className="image-wrapper">
                    <LazyLoadImage
                      className="hover-effect"
                      effect="blur"
                      src={qufreeLast.url}
                      alt="technology"
                    />
                    <div className="text-overlay">{qufreeLast.name}</div>
                  </div>
                ))}
              </div>
              <div className="owl-carousel-mob">
                <OwlCarousel {...carouselTechOptions}>
                  {qufreeTechStack.map((URL) => (
                    <div>
                      <img alt="techstack-url" src={URL.url} />
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="last-qufree-content">
            <img src={images.TAGLINE} alt="qufree-tagline"></img>
          </div>
        </div>
      </div>
      <HomePageFooter page={Solutions} />
      <Footer />
      <div></div>
    </div>
  );
};
export default Solutions;
