import React from 'react';
// import Footer from 'src/components/footer';
import './style.scss';
import { Link } from 'react-router-dom';
import Button from 'src/components/button';
import { scrollToTop } from 'src/shared/utils/appConstant';
import Modal from 'src/components/contact/components/modal';
import useModal from 'src/shared/hooks/useModal';

const FooterServices = () => {
  const headingServices = "Interested In Knowing More?";
  const { isOpen, toggle } = useModal();
  return (
    <div className='services-footer'>
      <div className='header-services-footer'>
        <p>{headingServices}</p>
        {window.innerWidth < 768 ?
          <Link to="/contact" onClick={scrollToTop}>
            <Button>Contact Us</Button>
          </Link>
          : <><Button onClick={toggle}>Contact Us</Button><Modal isOpen={isOpen} toggle={toggle}></Modal></>
        }
      </div>
    </div>
  )
}

export default FooterServices