import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { cardDataDesigning, images, scrollToTop } from 'src/shared/utils/appConstant';
import Cards from 'src/components/cards';
import HomePageFooter from '../home/components/Footer';
import Footer from "src/components/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const Designing = () => {
  return (
    <>
      <div className='page-container-Designing'>
        <div className='image-container'>
          <div className="text-container">
            <h1 className='heading drop-in'>Designing (UI/UX)</h1>
            <p className='heading-desc display-none drop-in-2'>In the artistry of Web Designing UI/UX, we sculpt visually stunning interfaces harmonized with intuitive interactions, weaving a seamless experience that not only engages but delights your user at every click.</p>
          </div>
          <p className='breadcrumb position-abs drop-in-2'>
            <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <Link className='breadcrumb-link' to={pageRoutes.Services}>Services</Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link className='breadcrumb-link' to={pageRoutes.Designing} onClick={scrollToTop}>UI/UX</Link>
            </p>
          </p>
          <div className='red-bg-box'></div>
        </div>

        <div className="about-our-service">
          <div className="left-section animate__animated animate__fadeInLeft animate__delay-1s">
            <h5>About Our Service</h5>
            <h2>UI & UX</h2>
            <h3>About Our Service</h3>
            <p>
              Wingman Partners excels in seamlessly integrating UI/UX for visually captivating websites. As architects of seamless online journeys, we prioritize innovation to craft exceptional user experiences. Join us for a lasting digital impression.
              <img alt="blue-box" className='blue-box' src={images.dark_blue_box} />
              <img alt="red-box" className='red-box' src={images.red_box} />
              <div className='red-box-sq'></div>
            </p>
            <img alt="blue-box" src={images.blue_box} />
          </div>
          <div className="right-section animate__animated animate__fadeInRight animate__delay-1s">
            <LazyLoadImage
              src={images.DESIGNING_IMG}
              alt='UI'
            />
          </div>
          <img src={images.blue_box} alt="blue-box" />
          <img src={images.circles} className='circles-Designing' alt="circle-img" />
        </div>

        <div className="lower-div">
          <h2>What We Offer</h2>
          <Cards cardData={cardDataDesigning} />
        </div>
        <HomePageFooter page="" />
        <Footer />
      </div>
    </>
  )
}

export default Designing