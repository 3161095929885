import React, { useState } from 'react';
import './style.scss';

interface CardProps {
  index: number;
  frontImage: string;
  frontContent: string;
  backImage: string;
  backHeader: string;
  backContent: string;
}
const Card: React.FC<CardProps> = ({
  index,
  frontImage,
  frontContent,
  backImage,
  backHeader,
  backContent,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleModal = (isMouseEnter: boolean): void => {
    if (isMouseEnter && window.innerWidth > 1040) {
      setIsFlipped(true);
    } else {
      setIsFlipped(false);
    }
  };
  return (
    <div
      className={`card ${isFlipped ? 'flipped' : ''}`}
      onMouseEnter={() => handleModal(true)}
      onMouseLeave={() => handleModal(false)}
    >
      <div className="card-face card-front ">
        <img src={frontImage} alt={`Front of card ${index}`} />
        <p className='card-text'>{frontContent}</p>
      </div>
      <div className="card-face card-back">
        {/* <img src={backImage} alt=''/> */}
        <div className="overlay-text">
          <p className='back-header'>{backHeader}</p>
          <p className='back-content'>{backContent}</p>
        </div>
      </div>
    </div>
  );
};

export default Card



