import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import { routes } from 'src/shared/utils/routes';
import { useMediaQuery } from '@mui/material';
import MyModal from './components/contact/components/modal';
import Contact from './components/contact';
import Sidebar from 'src/components/sidebar';

interface CustomRoute {
  path: string;
  exact: boolean;
  component: React.ComponentType<any>;
}
const ContactWrapper: React.FC = () => {
  const isMobileView = useMediaQuery('(min-width:767px)');
  return isMobileView ? <MyModal isOpen={true} toggle={() => {}} />: <Contact isOpen={true} toggle={() => {}} /> ;
};

function App() {
  return (      
    <Router>
        <Sidebar />
        <Routes>
        {routes.map((route : CustomRoute, index : number) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component/>}
            /> 
          ))}
          <Route path="/contact" element={<ContactWrapper />} />
          <Route path="*" element={<Navigate to="/" />} />
     </Routes>
    </Router>
  );
}

export default App;
