// import Contact from "src/components/contact"
export const pageRoutes = {
    Home: '/',
    Contact: '/contact',
    DevOps: '/devOps',
    Services: '/#services',
    LifeAtWmp: '/life-at-wmp',
    Awards: '/awards',
    Blog:'/blog',
    Leadership: '/leadership',
    Team: '/team',
    AboutUs: '/about-us',
    POC: '/proof-of-concepts',
    SoftwareIntegration: '/software-integration',
    ProductReEngg: '/product-rengineering',
    MobileAppDev: '/mobile-app-development',
    WebAppDev: '/web-app-development',
    SoftwareEngineering: '/software-engineering',
    CloudInfra: '/cloud-infra',
    CloudMigration: '/cloud-migration',
    DevOpsAuto: '/devops-automation',
    DevopsOperations: '/devops-operations',
    Security: '/security-cost',
    QA: '/qa',
    TestingAndAutomation: '/testing-and-auto',
    ApiDrivenTesting: '/api-driven-testing',
    PerformanceTesting: '/performance-testing',
    PenetrationTesting: '/penetration-testing',
    Operations: '/operations',
    TechManagement: '/technical-writing',
    ProjectManagement: '/project-management',
    ProductManagement: '/product-strategy-and-management',
    Support: '/l3-support',
    Designing: '/designing',
    WebDesigning: '/web-designing',
    AppDesigning: '/app-designing',
    InfoArchitectureDesign: '/info-architecture-design',
    SuccessStories: '/success-stories',
    NetworkManagement: '/network-management',
    Technology: '/iot-security-solutions',
    Solutions: '/solutions',
    CXOTulz: '/cxotulz',
    Trakwa: '/trakwa',
    Qufree: '/qufree',
    Hospitality: '/hospitality',
    Healthcare: '/healthcare',
    Transportation: '/transportation',
    TechSpaces: '/tech-enabled-spaces',
    EmpCommunity: '/employment-and-community-engagement',
    KitchenDining: '/kitchen-and-dining',
    NonGovOrg: '/non-governmental-organization',
}
