import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import {
  IMAGES,
  cxoTechStack,
  scrollToTop,
  carouselTechOptions,
} from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import HomePageFooter from "src/views/home/components/Footer";
import { cardDataSolutions, cardSolutions } from "src/shared/utils/string";
import { images } from "src/shared/utils/appConstant";
import "animate.css";
import "animate.css/animate.min.css";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface Props {
  cardDataSolution: {
    image: string;
    heading: string;
    subPoints: string[];
  }[];
}
interface PropsSolution {
  cardSolution: {
    card: string;
    heading: string;
    subPoints: string[];
  }[];
}
// eslint-disable-next-line
interface PropCxo {
  cxoLast: {
    name: string;
    url: string;
  }[];
}

const CardSolutions = ({ cardDataSolution }: Props) => {
  return (
    <div className="main-container-solution-cxo" id="card-solution">
      {cardDataSolution.map((value, index) => (
        <div className="card-solution" key={index}>
          <img
            src={value.image}
            alt="container-icons"
            className={`logo-${index}`}
          />
          <div className="services-card-heading">
            <h5>{value.heading}</h5>
          </div>
          {/* Mapping for sub-points */}
          {value.subPoints.map((points, subIndex) => (
            <div className="cxo-point-containers" key={subIndex}>
              {/* Image for each sub-point */}
              <img
                alt="bullet"
                src={images.solutionBullet}
                className="bullet-point-img"
              />
              <div className="cxo-point">
                <p dangerouslySetInnerHTML={{ __html: points }} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const CardInsideSolution = ({ cardSolution }: PropsSolution) => {
  return (
    <div className="main-container-solutions-cxo row" id="card-solutions">
      {cardSolution.map((value, index) => (
        <>
          <div className="card-solutions" key={index}>
            <div className={`vert-line-solutions vert-line-${index}`}></div>
            <div className="solution-card-heading">
              <p>{value.card}</p>
              <h5>{value.heading}</h5>
              {value.subPoints.map((points, subIndex) => (
                <p
                  key={subIndex}
                  dangerouslySetInnerHTML={{ __html: points }}
                />
              ))}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};
const Solutions = () => {
  return (
    <div className="cxotulz-container">
      <div className="cxotulz-image-container image-container position-rel">
        <p className="heading-desc-cxo display-none drop-in-2 breadcrumb-color">
          Unlocking the power of modernized data management, CXO Tulz reshapes
          the narrative of efficiency and innovation in business.
        </p>
        <div className="breadcrumb-cxo position-abs drop-in-2 breadcrumb-color">
          <Link
            to={pageRoutes.Home}
            onClick={scrollToTop}
            className="breadcrumb-color"
          >
            Home
          </Link>
          <span className="breadcrumb-separator breadcrumb-color"> / </span>
          <Link
            to={pageRoutes.Solutions}
            onClick={scrollToTop}
            className="breadcrumb-color"
          >
            Solutions
          </Link>
          <span className="breadcrumb-separator breadcrumb-color"> / </span>
          <p className="fw-light">
            <Link
              to={pageRoutes.CXOTulz}
              onClick={scrollToTop}
              className="breadcrumb-color"
            >
              CXOTulz
            </Link>
          </p>
        </div>
      </div>
      <div className="cxo-content-container ">
        <div className="upper-section-heading drop-in-2">
          <Link to="https://cxotulz.com" target="_blank" rel="noreferrer">
            <img src={IMAGES.CXOTULZ} alt="icon" />
          </Link>
        </div>
        <div className="content-heading">
          <p>
            As someone who has witnessed firsthand the challenges faced by a
            business when it comes to managing their cloud infrastructure, we
            can't emphasize enough how CXOTulz has transformed the game.
          </p>
        </div>

        <div className="cxo-main-content row ">
          <div className="up-content row">
            <div className="cv-mob-bg position-abs display-toggle">
              <img
                className="position-abs"
                src={IMAGES.DOT_CIRCLES}
                alt="dot"
              />
            </div>
            <div className="para-content col-md-6 animate__animated animate__fadeInLeft animate__delay-1s">
              <h1>
                What is <br /> CXOTulz ?
              </h1>
              <div className="dashboard-cxo-mob display-toggle">
                <img src={IMAGES.MOB_CXO} alt="dashboard-cxo" />
              </div>
              <p>
                <a href="https://cxotulz.com/" target="_blank" rel="noreferrer">
                  CXOTulz
                </a>{" "}
                is a robust cloud management solution designed to streamline the
                complexities of managing top-tier cloud providers such as AWS,
                Azure, and Google Cloud. With a focus on mitigating unexpected
                costs and vulnerabilities, our tool ensures unparalleled
                efficiency by optimizing cloud operations with precision and
                reliability. Discover a transformative experience in cloud
                management without revealing intricate details. 
              </p>
            </div>
            <div className="mid-content col-md-6 animate__animated animate__fadeInRight animate__delay-1s">
              <div className="dashboard-cxo-img display-none">
                <img src={IMAGES.DASHBOARD_CXO} alt="dashboard-cxo" />
              </div>
            </div>
          </div>

          <div className="center-content">
            <h2>
              Here’s how it will make your{" "}
              <span className="color-text">life easier</span>
            </h2>
          </div>
          <div className="card-solution-points">
            <CardSolutions cardDataSolution={cardDataSolutions} />
          </div>
          <div className="cxo-role row">
            <h3>
              Unlock seamless{" "}
              <span className="color-text">Cloud Management </span>for every
              role
            </h3>
            <p>
              Anyone who seeks to manage and optimize their cloud operations can
              benefit from using CXOTulz. This includes:
            </p>
            <CardInsideSolution cardSolution={cardSolutions} />
          </div>
          <div className="cxo-techstack row">
            <h4>
              {" "}
              <span className="color-text"> Technology </span> Stack{" "}
            </h4>
            <p>
              CXOTulz leverages cutting-edge technology for seamless
              functioning.
            </p>
          </div>
          <div className="lower-div">
            <div className="technologies">
              <div className="images-Tech">
                {cxoTechStack.map((cxoLast, index) => (
                  <div key={index} className="image-wrapper">
                    <LazyLoadImage
                      className="hover-effect"
                      effect="blur"
                      src={cxoLast.url}
                      alt="technology"
                    />
                    <div className="text-overlay">{cxoLast.name}</div>
                  </div>
                ))}
              </div>
              <div className="owl-carousel-mob">
                <OwlCarousel {...carouselTechOptions}>
                  {cxoTechStack.map((URL) => (
                    <div>
                      <img alt="techstack-url" src={URL.url} />
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="cxo-last-content col-md-4">
            <div className="last-img animate__animated animate__fadeInLeft animate__delay-1s">
              <img src={images.CxoReport} alt="report-cxo" />
            </div>
            <div className="last-p-content animate__animated animate__fadeInRight animate__delay-1s">
              <p>
                Revolutionize your cloud management experience with detailed
                analytics, offline analysis tools, and many more hidden features
                for optimization and strategy. Uncover the full potential of
                your cloud infrastructure by onboarding it on{" "}
                <a href="https://cxotulz.com/" target="_blank" rel="noreferrer">
                  CXOTulz
                </a>{" "}
                today.
              </p>
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter page={Solutions} />
      <Footer />
      <div></div>
    </div>
  );
};
export default Solutions;
