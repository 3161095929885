import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import Cards from 'src/components/cards';
import { cardDataOperations, images, scrollToTop, teamOperationsImages, IMAGES } from 'src/shared/utils/appConstant';
import HomePageFooter from '../home/components/Footer';
import Footer from 'src/components/footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const Operations = () => {
  return (
    <>
      <div className='page-container-Operations'>
        <div className='image-container'>
          <div className="text-container">
            <h1 className='heading drop-in'>Operations</h1>
            <p className='heading-desc drop-in-2'>With our operations excellence at its core—offering seamless technical solutions, robust project and product management, and round-the-clock support for unparalleled success.</p>
          </div>
          <p className='breadcrumb drop-in-2'>
            <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="breadcrumb-light">
              <Link className='breadcrumb-link' to={pageRoutes.Services}>Services</Link>
            </p>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link className='breadcrumb-link' to={pageRoutes.Operations} onClick={scrollToTop}>Operations</Link>
            </p>
          </p>
          <div className='red-bg-box'></div>
        </div>

        <div className="about-our-service">
          <div className="left-section animate__animated animate__fadeInLeft animate__delay-1s">
            <h5>About Our Service</h5>
            <h2>Operations</h2>
            <div className="ops-mob-bg position-abs display-toggle">
                    <img
                      className="position-abs"
                      src={IMAGES.DOT_CIRCLES}
                      alt="dot"
                    />
            </div>
            <h3>About Our Service</h3>
            <p>
              At the heart of efficiency, our Operations team orchestrates a symphony of seamless processes, ensuring your digital infrastructure operates at peak performance
              <img alt="blue-box" className='blue-box' src={images.dark_blue_box} />
              <img alt="red-box" className='red-box' src={images.red_box} />
              <div className='red-box-sq'></div>
            </p>
            <img alt="blue-box" src={images.blue_box} />
          </div>
          <div className="right-section">
            <LazyLoadImage
              src={images.OPERATIONS_IMG}
              alt='operations'
              className='animate__animated animate__fadeInRight animate__delay-0.5s'
            />
          </div>
        </div>

        <div className="lower-div">
          <h2>What We Offer</h2>
          <Cards cardData={cardDataOperations} />
          <div className="teams-accreditation">
            <h2>Team’s Accreditation</h2>
            <p>Our team have proven expertise across a range of technologies.</p>
            <div className="images-teams-accreditation">
              {teamOperationsImages.map((URL, index) => (
                <LazyLoadImage
                  src={URL}
                  alt='operations'
                />
              ))}
            </div>
          </div>
        </div>
        <HomePageFooter page="" />
        <Footer />
      </div>
    </>
  )
}

export default Operations