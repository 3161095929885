import React from 'react'
import './style.scss';
import 'bootstrap/dist/css/bootstrap.css'
import { IMAGES,images, dataBlogs, scrollToTop } from '../../../../shared/utils/appConstant';
import { headingBlogs } from 'src/shared/utils/string';
import Button from 'src/components/button';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const DummyImageURL = 'https://via.placeholder.com/100x100'; // Replace with your dummy image URL
const Blogs = () => {
  return (
    <div className='page-container-Blogs'>
      <div className="main-container-Blogs">            {/* headings */}
        <h1>{headingBlogs}</h1>
        <img
                className="position-abs display-toggle"
                src={IMAGES.DOT_CIRCLES}
                alt="dot"
              />
        <div className="card-heading-Blogs">
          Read <br/> Our <br />  Blogs
          <img alt="blue-box" className='blue-box display-toggle' src={images.blue_box} />
          <img alt="..." className='circles-Test-mob' src={images.blue_box} />
        </div>
        <div className="row blog-card-row">                {/* cards */}
          {dataBlogs.map((value, index) => (
            <div className='col-md-4 blog-card-col ' key={index}>
              <LazyLoadImage
                className={'card-img-top'}
                src={value.image}
                placeholderSrc={DummyImageURL}
                effect="blur"
                alt="sample-file"
              />
              <div className="card-body blog-card-body">
                <div className="blog-card-content">
                  <p>
                    <span className="blog-card-first-word">By:</span>
                    {` ${value.by} | Date: ${value.date}`}
                  </p>
                  <a href={value.URL} target="_blank" rel="noreferrer" className="URL-desc">
                    {value.UrlDesc}
                  </a>
                </div>
              </div>
              <span className="read-btn-blog">
                <a href={value.URL} target="_blank" rel="noreferrer" className="effect hover-effect">
                  READ NOW
                  <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
                </a>
              </span>
            </div>
          ))}                {/* button for navigating to other blogs */}
          <div className="col-md-4 last-div-Blogs">
            <div className="row first-Blogs">Recent blogs</div>
            <div className="row second-Blogs">
              Read <br /> Our <br /> Blogs
            </div>
            <Link to={pageRoutes.Blog} onClick={scrollToTop}><Button>Explore All</Button></Link>
          </div>
        </div>
      </div>
      <div className='sideBorder'></div>
    </div>
  )
};
export default Blogs;