import React from 'react'
import './style.scss'
import { images } from 'src/shared/utils/appConstant';
interface Props {
  cardDataServices: {
    heading: string;
    subPoints: string[];
  }[]
}

const CardsServices = ({ cardDataServices }: Props) => {
  return (
    <div className='main-container-services' id='card-services'>
      {cardDataServices.map((value) => (
        <div className='card-services'>
          <div className='services-card-heading'>
          <h5>{value.heading}</h5>
          </div>
          {value.subPoints.map((points, index) => (
            <div className='sub-point-container'>
             <img alt="bullet" src={images.bullet} className='bullet-point-img' />
            <div className='sub-points'>
              <p key={index} dangerouslySetInnerHTML={{ __html: points }} />
            </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default CardsServices