import React from "react";
import "./style.scss";
import SuccessFull from 'src/shared/assets/images/successfull.svg';
import Modal from "react-modal";

Modal.setAppElement('#root');

const SuccessFullModal = (props: any) => {

    const overlayStyles: any = {
        position: 'fixed',
        zIndex: 1000,
        top: '0',
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const contentStyles: any = {
        inset: 0,
        zIndex: 5,
        backgroundColor: 'white',
        width: '40%',
        height: '40%',
        overflow: 'hidden',
        position: 'relative',
        margin: '0 auto',
        border: 'none',
        borderRadius: '5px',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    if (window.innerWidth < 450) {
        contentStyles.width = '70%';
        contentStyles.height = '30%';
    }

    return (
        <Modal
            isOpen={true}
            // onRequestClose={props}
            ariaHideApp={false}
            contentLabel="Modal-box"
            style={{
                overlay: overlayStyles,
                content: contentStyles,
            }}
        >
            <div className="center-container">
                <img src={SuccessFull} alt="YourImage" className="successful-image" />
                <p className="successful-p">Your details has been successfully submitted!</p>
            </div>
        </Modal>
    )
}

export default SuccessFullModal;