import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  carouselImagesCaseStudy,
  FIVE_THOUSAND_CONST,
  scrollToTop,
  cardDataSuccessStories
} from "src/shared/utils/appConstant";
import { carouselHeading } from "src/shared/utils/string";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";
import Button from "src/components/button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IMAGES } from "src/shared/utils/appConstant";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the desired effect styles
import 'animate.css';
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";


const DummyImageURL = "https://via.placeholder.com/100x100"; // Replace with your dummy image URL
const CaseStudy = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the activeIndex to move to the next slide
      setActiveIndex(
        prevIndex => (prevIndex + 1) % carouselImagesCaseStudy.length
      );
    }, FIVE_THOUSAND_CONST); // Change slide every 5 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  // function for changing colours of alternate words of text
  const AlternateColorText = (text: string, index: number) => {
    // Split the text into an array of words
    const words = text.split(" ");

    // Map through the words and apply a different color to alternate words
    const coloredWords = words.map((word, index) => (
      <span key={index} className={index % 2 === 0 ? "even-word" : "odd-word"}>
        {word}&nbsp;{/* Add a non-breaking space to separate words */}
      </span>
    ));

    return <p key={index}>{coloredWords}</p>;
  };

  const [prevButtonEnable, setprevButtonEnable] = useState(false);
  const [nextButtonEnable, setnextButtonEnable] = useState(true);

  //   for enabling/disabling button

  useEffect(() => {
    if (activeIndex === 0) {
      setprevButtonEnable(false);
      setnextButtonEnable(true);
    } else if (activeIndex === carouselImagesCaseStudy.length - 1) {
      setprevButtonEnable(true);
      setnextButtonEnable(false);
    } else {
      setprevButtonEnable(true);
      setnextButtonEnable(true);
    }
  }, [activeIndex]);

  const navigateToPath = () => {
    const newPath = cardDataSuccessStories[activeIndex].routeLink; // Replace with the path you want to navigate to
    navigate(newPath);
    scrollToTop();
  };

  return (
    <div className="page-container-CaseStudy">
      <div className="main-container-CaseStudy row">
        <div className="content-CaseStudy col-md-6">
        <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
          {/* heading part */}
          <div className="casestudy-upper-section">
            <h1>
              Success <br />
              Stories
            </h1>
            <div className="casestudy-mob-bg position-abs display-toggle">
              <img
                className="position-abs"
                src={IMAGES.DOT_CIRCLES}
                alt="dot"
              />
            </div>
            <h2>Empowering Partners through Technological Breakthroughs.</h2>
            <p>
              Global startups and major corporations trust us for innovative
              solutions in various industries.
            </p>
          </div>
          <div className="lower-div-CaseStudy">
            {/* arrow buttons for navigating carousel content */}
            <div className="Arrows-CaseStudy">
              <div className="ind-CaseStudy">
                <p>{activeIndex + 1}</p>
                <p>/</p>
                <p>{carouselImagesCaseStudy.length}</p>
              </div>
              <div className="case-study-round-btn">
                <button
                  className={`btn rounded-circle btn-lg ${
                    prevButtonEnable ? "enableButton" : "disableButton"
                  }`} disabled={!prevButtonEnable}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => setActiveIndex(Math.max(0, activeIndex - 1))}
                  />
                </button>
                <button
                  className={`btn rounded-circle btn-lg ${
                    nextButtonEnable ? "enableButton" : "disableButton"
                  }`}
                  disabled={!nextButtonEnable}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    onClick={() =>
                      setActiveIndex(
                        Math.min(
                          carouselImagesCaseStudy.length - 1,
                          activeIndex + 1
                        )
                      )
                    }
                  />
                </button>
              </div>
            </div>

            {/* carousel for images heading */}
            <div className="carousel-heading-CaseStudy">
              <Carousel
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                autoPlay={true}
                interval={5000}
                showThumbs={false}
                selectedItem={activeIndex}
                onChange={index => setActiveIndex(index)}
              >
                {carouselHeading.map((heading, index) =>
                  AlternateColorText(heading, index)
                )}
              </Carousel>
              <div className="btn-container">
                <Button onClick={navigateToPath}>Read Now</Button>
                <Link to= {pageRoutes.SuccessStories} onClick={scrollToTop}>
                  <Button>Explore All</Button>
                </Link>
              </div>
            </div>
            {/* carousel for mobile view */}
            <div className="carousel-img-mob-CaseStudy">
              <Carousel
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                autoPlay={true}
                interval={5000}
                showThumbs={false}
                selectedItem={activeIndex}
                onChange={index => setActiveIndex(index)}
              >
                {carouselImagesCaseStudy.map((URL, index) => (
                  <div className="slide1" key={index}>
                    <img alt="..." src={URL} />
                  </div>
                ))}
              </Carousel>
            </div>

            {/* arrow button for mobile screen */}
            <div className="Arrows-mob-CaseStudy">
              <div className="ind-mob-CaseStudy">
                <p>{activeIndex + 1}</p>
                <p>/</p>
                <p>{carouselImagesCaseStudy.length}</p>
              </div>
              <div className="case-study-btn display-toggle">
                <button
                  className={`btn rounded-circle ${
                    prevButtonEnable ? "enableButton" : "disableButton"
                  }`}
                  disabled={!prevButtonEnable}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ fontSize: "2rem", color: "white" }}
                    onClick={() => setActiveIndex(Math.max(0, activeIndex - 1))}
                  />
                </button>
                <button
                  className={`btn rounded-circle ${
                    nextButtonEnable ? "enableButton" : "disableButton"
                  }`}
                  disabled={!nextButtonEnable}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ fontSize: "2rem", color: "white" }}
                    onClick={() =>
                      setActiveIndex(
                        Math.min(
                          carouselImagesCaseStudy.length - 1,
                          activeIndex + 1
                        )
                      )
                    }
                  />
                </button>
              </div>
            </div>
          </div>
          </AnimationOnScroll>
        </div>

        {/* vertical line between both carousel */}
        <div className="vert-line-CaseStudy"></div>

        {/* right carousel(for images) */}
        <div className="carousel-img-CaseStudy col-md-6 ">
        <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            interval={5000}
            showThumbs={false}
            selectedItem={activeIndex}
            onChange={index => setActiveIndex(index)}
          >
            {carouselImagesCaseStudy.map((URL, index) => (
              <div className="slide-CaseStudy" key={index}>
                <LazyLoadImage
                  src={URL}
                  placeholderSrc={DummyImageURL}
                  effect="blur"
                  alt="sample-file"
                />
              </div>
            ))}
          </Carousel>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="sideBorder"></div>
    </div>
  );
};

export default CaseStudy;
