import React from 'react';
import './style.scss';
import 'react-lazy-load-image-component/src/effects/blur.css'; 


const CompanyOverview = () => {
  return (
    <div className="company-overview-home">
      <h1>Company Overview</h1>
      <p>We are a cloud-first company. We help businesses build  
        resilience into their infrastructure with DevOps, Development, IT  
        Infrastructure Services, and Cloud Solutions. At Wingman Partners, we help companies transform digitally and reduce the deployment cycle by  
        up to 70% through our end-to-end Development, Cloud, and DevOps  
        services. <br></br><br></br>We develop quality products with continuous testing and CI/CD  
        pipelines, and often with half the overall maintenance cost. We can also  
        eliminate bottlenecks and reduce manual hours by 4x via automation. We  
        can help you achieve zero-downtime deployments and improve customer 
        experience. Our IT experts also provide 24/7 consulting support. Our  
        expertise in PWA and hybrid app development can also help you explore growth opportunities in the digital space. </p>
        <div className='sideBorder'></div>
    </div>
    
  );
};

export default CompanyOverview;
