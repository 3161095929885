import React, { useState, useEffect } from "react";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
import Button from "src/components/button";
import Footer from "src/components/footer";
import { cardBlogData } from "src/shared/utils/string";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/shared/utils/appConstant";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the desired effect styles
import backgroundImage from "../../shared/assets/images/blog-bg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

const DummyImageURL = "https://via.placeholder.com/100x100"; // Replace with your dummy image URL

const Blog = () => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    if (showAll) {
      scrollToTop(); // if show less click then navigate to top.
    }
    setShowAll(!showAll);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const threshold = windowWidth < 600 ? 4 : 6;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLDivElement;
          const imageUrl = target.getAttribute("data-background-image");
          if (imageUrl) {
            target.style.backgroundImage = `url(${imageUrl})`;
          }
          observer.unobserve(target);
        }
      });
    });

    document.querySelectorAll("[data-background-image]").forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="blog-container">
      <div className="image-container position-rel"
        data-background-image={backgroundImage}
      >
        <LazyLoadImage
          alt="blog-background"
          effect="blur"
          // src={backgroundImage}
          // placeholderSrc={DummyImageURL} // Replace with a placeholder image URL
          wrapperClassName="background-image-placeholder"
        />
        <div className="text-container">
          <h1 className="heading drop-in">Blogs</h1>
          <p className="heading-desc display-block display-none drop-in-2">
            Join us on the realm of ideas, through our blogs where knowledge
            meets creativity. Uncover a treasure trove of blogs with expert
            insight, and valuable information tailored for the curious minds.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link to={pageRoutes.Home} onClick={scrollToTop}>
            Home
          </Link>
          <span className="breadcrumb-separator"> / </span>
          <p className="fw-light">
            <Link to={pageRoutes.Blog} onClick={scrollToTop}>
              Blogs
            </Link>
          </p>
        </div>
        <div className="red-bg-box"></div>
      </div>
      <div className="blog-card-container">
        <div className="blog-insight-header">
          <h2>Insights Unveiled</h2>
        </div>
        <div className="row blog-card-row">
          {cardBlogData.map((value, index) => (
            <div
              key={index}
              className={`col-md-4 blog-card-col ${
                !showAll && index >= threshold ? "display-void" : ""
              }`}
            >
              <LazyLoadImage
                src={value.image}
                className="card-img-top"
                placeholderSrc={DummyImageURL}
                effect="blur"
                alt="blog"
              />
              <div className="card-body blog-card-body">
                <div className="blog-card-content">
                  <p>
                    <span className="blog-card-first-word">By:</span>
                    {` ${value.by} | Date: ${value.date}`}
                  </p>
                  <a
                    href={value.URL}
                    target="_blank"
                    className="URL-desc"
                    rel="noreferrer"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {value.UrlDesc}
                    <br />
                  </a>
                </div>
              </div>
              <span className="read-btn-blog">
                <span>
                  <a
                    href={value.URL}
                    target="_blank"
                    className="effect hover-effect"
                    rel="noreferrer"
                  >
                    READ NOW
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="arrow-icon"
                    />
                  </a>
                </span>
              </span>
            </div>
          ))}
        </div>
        <div className="see-all-btn">
          <Button onClick={toggleShowAll}>
          {showAll ? "Show less" : "See all"}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Blog;
