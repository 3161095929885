import React from 'react';
import { Link } from 'react-router-dom';
import {successStories} from 'src/shared/utils/string';
import { cardDataSuccessStories, scrollToTop } from 'src/shared/utils/appConstant';
import './style.scss';
import AnimationCards from 'src/components/animationCards';
import Footer from "src/components/footer";
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const SuccessStories = () => {
  return (
    <>
    <div className='page-container'>
        <div className='image-container success-image-container'>
          <div className="text-container">
            <h1 className='heading drop-in'>{successStories.heading}</h1>
            <p className='heading-desc drop-in-2'>{successStories.desc}</p>
          </div>
          <div className='breadcrumb position-abs drop-in-2'>
            <Link className='breadcrumb-link' to = {pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
            <Link className='breadcrumb-link' to = {pageRoutes.SuccessStories} onClick={scrollToTop}>Success Stories</Link>
            </p>
          </div>
            <div className='red-bg-box'></div>
        </div>

        <div className="cards-div">
        <p className='heading-mob'>{successStories.desc}</p>
          <AnimationCards cardData={cardDataSuccessStories}/>
        </div>
        <div>
      <Footer />
    </div>
    </div>
    </>
  )
}

export default SuccessStories