import React from 'react'
import './style.scss'
import 'bootstrap/dist/css/bootstrap.css'

interface Props{
  images: string[];
}

const CarouselTestimonials = ({images} : Props) => {
  return (
 
      <div className='slider-Testimonials row'>
        <div className='slide-track-Testimonials'>
          {
            images.map((URL,index) => (
              <div className='col-md-1' key={index}>
                <img alt="..." className = {('image'+index)} src = {URL}/>
              </div>
            ))
          }
          {
            images.map((URL,index) => (
              <div className='col-md-1' key={index}>
                <img alt="..." className = {('image'+index)} src = {URL} />
              </div>
            ))
          }
        </div>
      </div>
  )
}

export default CarouselTestimonials