import React from 'react';
import BreadcrumbQA from '../BreadcrumbQA';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataAPIDrivenTesting } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const ApiDrivenTesting = () => {
  return (
    <div className="page-container-api-driven-testing">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>API Driven Testing</h1>
            <p className='heading-desc display-none drop-in-3'>API-driven testing involves assessing the functionality, performance, and security of a software system by directly testing its application programming interfaces (APIs), ensuring robust communication and interaction between software components.</p>
          </div>
            <Link to="/api-driven-testing" className='no-decoration'><BreadcrumbQA text={"API Driven Testing"} /></Link>  
            <div className='red-bg-box'></div>
        </div>
        <CardsServices cardDataServices={cardDataAPIDrivenTesting} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default ApiDrivenTesting