import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import { cardSolution } from "src/shared/utils/string";
import "animate.css";
import "animate.css/animate.min.css";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

interface Props {
  cardDataSolution: {
    image: string;
    desc: string;
    explore: string;
    routeLink: string;
  }[];
}
const CardSolutions = ({ cardDataSolution }: Props) => {
  const borderColors = ["#007EA7", "#FEDC2E", "#63A9D5"];
  return (
    <div className="main-container-solutions">
      {cardSolution.map((value, index) => (
        <div
          className="card-sol"
          key={index}
          style={{ borderLeftColor: borderColors[index % borderColors.length] }}
        >
          <img src={value.image} alt="container-icons" className="logo" />
          <div className="services-card-heading">
            <p>{value.desc}</p>
          </div>
          <div className="explore-now">
            <Link
              to={value.routeLink}
              className="explore-text"
              style={{ color: borderColors[index % borderColors.length] }}
              onClick={scrollToTop}
            >
              <p className="explore-text">EXPLORE MORE</p>
              <img
                src={value.explore}
                alt="explore-icon"
              />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
const Solutions = () => {
  return (
    <div className="solutions-container">
      <div className="solutions-image-container image-container position-rel">
        <div className="text-container">
          <h1 className="heading drop-in">Solutions</h1>
          <p className="heading-desc display-none drop-in-2">
            Discover business excellence with expertly curated solutions
            designed for seamless operations and enhanced efficiency to ensure
            success.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link to={pageRoutes.Home} onClick={scrollToTop}>
            Home
          </Link>
          <span className="breadcrumb-separator"> / </span>
          <p className="fw-light">
            <Link to={pageRoutes.Solutions}>Solutions</Link>
          </p>
        </div>
        <div className="red-bg-box"></div>
      </div>
      <div className="solutions-content-container ">
        <div className="sol-upper-section-heading drop-in-2">
          <h2>Organization's Technology Catalysts</h2>
        </div>
        <div className="sol-content-heading">
          <p>
            Our innovative solutions streamline daily operations, enhancing
            efficiency and simplifying tasks for seamless management.
          </p>
        </div>

        <div className="solutions-main-content row ">
          <div className="solution-points animate__animated animate__fadeInLeft animate__delay-1s">
            <CardSolutions cardDataSolution={cardSolution} />
          </div>
        </div>
      </div>
      <Footer />
      <div></div>
    </div>
  );
};
export default Solutions;
