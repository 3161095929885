import React from 'react';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataSecurity } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import BreadcrumbDevOps from '../BreadcrumbDevOps';
import { Link } from 'react-router-dom';

const Security = () => {
  return (
    <div className="page-container-security">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>Security & Cost Optimisation</h1>
          </div>
            <Link to="/security-cost" className='no-decoration'><BreadcrumbDevOps text={"Security & Cost Optimisation"} /></Link>  
            <div className='red-bg-box'></div>
        </div>
        <CardsServices cardDataServices={cardDataSecurity} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default Security