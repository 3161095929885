import React from 'react';
import BreadcrumbQA from '../BreadcrumbQA';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataPenetrationTesting } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const PenetrationTesting = () => {
  return (
    <div className="page-container-penetration-testing">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>Penetration Testing</h1>
            <p className='heading-desc display-none drop-in-3'>Penetration testing, a proactive cybersecurity approach, involves simulated attacks to uncover and address vulnerabilities in systems, ensuring robust defense against real-world threats.</p>
          </div>
            <Link to="/penetration-testing" className='no-decoration'><BreadcrumbQA text={"Penetration Testing"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataPenetrationTesting} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default PenetrationTesting