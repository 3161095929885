import React from 'react';
import './style.scss';
import { teamOperationsImages,teamDevopsImages, teamQAImages} from 'src/shared/utils/appConstant';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles


const Accreditations = () => {
  return (
    <div className="teams-accreditation-home">
      <h1>Team’s Accreditation</h1>
      <p>Our team has proven expertise across a range of technologies.</p>

      {/* DevOps Images */}
      <div className="accreditation-home">
        {teamDevopsImages.map((URL, index) => (
          <LazyLoadImage
            key={index}
            src={URL}
            alt="teams-accreditation-home"
          />
        ))}
        {teamQAImages.map((URL, index) => (
          <LazyLoadImage
            key={index}
            src={URL}
            alt="teams-accreditation-home"
          />
        ))}
        {teamOperationsImages.map((URL, index) => (
          <LazyLoadImage
            key={index}
            src={URL}
            alt="teams-accreditation-home"
          />
        ))}
      </div>
      <div className='sideBorder'></div>
    </div>
  );
};

export default Accreditations;
