import React from 'react'
import './style.scss'
import { gifs, scrollToTop } from 'src/shared/utils/appConstant';
import { dataServices } from 'src/shared/utils/string';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles

const DummyImageURL = 'https://via.placeholder.com/100x100'; // Replace with your dummy image URL

function Services() {

    return (
        <div className='page-container-services' id='services'>
            {/* for headings */}
            <div className='heading-services'>
                <h1>{dataServices.heading}</h1>
                <div>
                    <p>{dataServices.subHeading}</p>
                </div>
            </div>

            {/* gifs container */}
            <div className='gif-container-services'>
                {gifs.map((value, index) => (
                    <Link to={value.routeLink} onClick={scrollToTop} key={index}>
                        <div key={index}>
                            <LazyLoadImage
                                className={'gif' + index}
                                src={value.path}
                                placeholderSrc={DummyImageURL}
                                effect="blur"
                                alt="sample-file"
                            />
                            <p>{value.val}</p>
                        </div>
                    </Link>
                ))}
             </div>   
            {/* Vertical line on the right */}
            <div className='sideBorder'></div>
        </div>
    )
}

export default Services