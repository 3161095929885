import React from 'react';
import './style.scss';
import { empcommunity } from 'src/shared/utils/string';
import StoryDescription from '../StoryDescription';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const EmpCommunity = () => {


  return (
    <div className='page-container-empcommunity'>
      <div className='image-container'>
        <h1 className='heading'>Employment and Community Engagement</h1>
        <p className='breadcrumb'>
          <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link' to= {pageRoutes.SuccessStories} onClick={scrollToTop}>Success Stories</Link>
          <span className="breadcrumb-separator"> / </span>
          <Link className='breadcrumb-link fw-light' to={pageRoutes.EmpCommunity} onClick={scrollToTop}>Employment and Community Engagement</Link>

        </p>
        <div className='red-bg-box'></div>
      </div>
      <StoryDescription storyData={empcommunity} />
      <Footer />
    </div>
  )
}

export default EmpCommunity