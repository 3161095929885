import React from 'react'
import { Link } from 'react-router-dom'
import { scrollToTop } from 'src/shared/utils/appConstant'
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant'

interface Props {
    text: string,
}

const BreadcrumbDesigning = ({text}: Props) => {
  return (
    <p className='breadcrumb'>
        <Link className='breadcrumb-link' to = {pageRoutes.Home} onClick={scrollToTop}>Home </Link>
        <span className="breadcrumb-separator"> / </span>
        <Link className='breadcrumb-link' to = {pageRoutes.Services}>Services</Link>
        <span className="breadcrumb-separator"> / </span>
        <Link className='breadcrumb-link' to = {pageRoutes.Designing} onClick={scrollToTop}>UI & UX</Link>
        <span className="breadcrumb-separator"> / </span>
        <p className='fw-light' onClick={scrollToTop}>{text}</p>
    </p>
  )
}

export default BreadcrumbDesigning