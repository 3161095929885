import React from 'react'
import './style.scss';
import Button from 'src/components/button';
import {heading, headingDesc} from 'src/shared/utils/string'
import Modal from "src/components/contact/components/modal";
import useModal from 'src/shared/hooks/useModal';
import { Link } from 'react-router-dom';
import { scrollToTop, SHOW_BORDER_SCREEN } from 'src/shared/utils/appConstant';

interface Props{
    page : any
}

const HomePageFooter=(page : Props) => {   
    const { isOpen, toggle } = useModal();

    return (
        <>

        <div className='page-container-footer'>
            {/* content above footer */}
            <div className="container header-footer">
                <div className="row heading-footer">{heading}</div>
                <div className="row heading-desc-footer">{headingDesc}</div>
                    <div className='btnsd-footer'>
                        {window.innerWidth < 768 ?
                            <Link to="/contact" onClick={scrollToTop}>
                                <Button>Contact Us</Button>
                            </Link>
                            : <><Button onClick={toggle}>Contact Us</Button><Modal isOpen={isOpen} toggle={toggle}></Modal></>
                        }
                    </div>
                    {
                        page.page === SHOW_BORDER_SCREEN ? <div className='sideBorder'></div> : <></>
                    }
                    
            </div>
        </div>
        
        </>
    )
}

export default HomePageFooter

