import React, { useState, useEffect } from 'react';
import './style.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { carouselImages, caraouselImagesMob, FIVE_THOUSAND_CONST, images } from '../../shared/utils/appConstant';
import { homeData } from 'src/shared/utils/string';
import CaseStudy from './components/CaseStudy';
import Services from './components/services';
import Testimonials from './components/Testimonials';
import Blogs from './components/Blogs';
import HomePageFooter from './components/Footer';
import Footer from "src/components/footer";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Accreditations from './components/TeamAccreditations';
import CompanyOverview from './components/companyOverview';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles

const DummyImageURL = 'https://via.placeholder.com/100x100'; // Replace with your dummy image URL


function Home() {
  // for future refernce to add custom image behind cursor.

  // const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Update cursor position on mouse move
  // const handleMouseMove = (e: MouseEvent) => {
  //   setCursorPosition({ x: e.clientX, y: e.clientY });
  // };

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Check if there's an ID in the hash
    const hash = window.location.hash;

    if (hash) {
      // Use the ID to scroll to the specified section
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the activeIndex to move to the next slide
      setActiveIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, FIVE_THOUSAND_CONST); // Change slide every 5 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  const scrollToServices = () => {
    const servicesElement = document.getElementById('services');
    if (servicesElement) {
      servicesElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='scroll-x'>
        <div className='page-container-Home drop-in'>
          {/* carousel */}
          <Carousel
            showArrows={false}
            showIndicators

            autoPlay={true}
            interval={FIVE_THOUSAND_CONST}
            infiniteLoop={true}
            selectedItem={activeIndex}
            onChange={(index) => setActiveIndex(index)}
            transitionTime={310}
            swipeable={false}
          >
            {carouselImages.map((URL, index) => (
              <div className='slide-Home' key={index}>

                {/* carousel images */}
                <LazyLoadImage
                  key={index}
                  src={URL}
                  className="carousel-img"
                  placeholderSrc={DummyImageURL}
                  effect="blur"
                  alt="sample-file"
                />
                <img className='carousel-img-mob' alt="sample_file" src={caraouselImagesMob[index]} key={index} />

                {/* carousel data */}
                <div className='caraousel-content-Home'>
                  <h2 className={index === 0 ? 'caraousel-content-heading0-Home drop-in' : 'caraousel-content-heading-Home'}>{homeData[index].heading}</h2>
                  <p className='drop-in-2'>{homeData[index].desc}</p>
                  <div className='loading-img-container-Home'>
                    <div className={index === 0 ? 'loader-active' : 'loader-inactive'} />
                    <div className={index === 1 ? 'loader-active' : 'loader-inactive'} />
                    <div className={index === 2 ? 'loader-active' : 'loader-inactive'} />
                  </div>
                </div>
                <div className='red-rect-Home'></div>
              </div>
            ))}
          </Carousel>

          {/* <Link to='/#services' onClick={scrollToServices}> */}
          <div className='scroll-container'>
            <div onClick={scrollToServices} className='scroll-inner-container'>
              <div className='scroll-mouse-container'>
                <img className='scroll-mouse' alt="mouse-scroller" src={images.SCROLL_DOWN} />
              </div>
              <div className='scroll-text-container'>
                <p className='scroll-text'>Scroll Down</p>
                <div id="line_r" className="line"></div>
              </div>
            </div>
          </div>

          {/* calling other components */}
        </div>
        <div className='pd-left'><CompanyOverview /></div>
        <div id='services' className='pd-left'>
          <Services />
          <CaseStudy />
          <Testimonials />
          <Blogs />
          <Accreditations />
          <HomePageFooter page="home" />
          <Footer />
        </div>
      </div>

    </>

  );
}

export default Home;
