import React from 'react';
import BreadcrumbQA from '../BreadcrumbQA';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { cardDataTestingAutomation } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const TestingAndAutomation = () => {
  return (
    <div className="page-container-testing-automtaion">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>Testing & Automation</h1>
            <p className='heading-desc display-none drop-in-3'>Testing and automation are essential aspects of software development and quality assurance.
              There are various topics you can cover under testing and automation, including</p>
              </div>    
              <Link to="/testing-and-auto" className='no-decoration '><BreadcrumbQA text={"Testing & Automation"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={cardDataTestingAutomation} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default TestingAndAutomation