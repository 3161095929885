import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { IMAGES, SIDEBAR_ITEMS, scrollToTop } from "src/shared/utils/appConstant";
// import images
import wmp_logo from "src/shared/assets/images/wmp-logo.svg";
import hamburger_icon from "src/shared/assets/images/hamburger-icon.svg";
import menu_icon from "src/shared/assets/images/menu-icon.svg";
import cross from "src/shared/assets/images/cross.svg";
import dropdown_arrow from "src/shared/assets/images/dropdown-arrow.svg";
import { Link } from "react-router-dom";
import useModal from "src/shared/hooks/useModal";
import Modal from "../contact/components/modal";
import { SOCIAL_LOGIN_ENDPOINT } from "src/shared/utils/string";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState<Boolean>(false); // To store the state of Sidebar in Mobile View whether it is open or not
  const [dropdownSelected, setDropdownSelected] = useState<String>(""); // To store the state of Dropdown state in Mobile View whether it is open or not under Sidebar options
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the component
      if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
        // Set the state to false when clicked outside
        setSidebarOpen(false);
      }
    };

    // Add event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // const sidebarToggle = !sidebarOpen;
    // setSidebarOpen((prev: Boolean) => !prev);
    const sidebar = document.getElementById("sidebarContent");
    const items = document.getElementsByClassName("items") as HTMLSelectElement;
    const selectedArray = Array.from(items);

    //  Sidebar display toggle to show and hide the Sidebar
    if (sidebarOpen) {
      sidebar?.classList.add("sidebar_items");
      sidebar?.classList.remove("display-none");
      selectedArray.forEach((element) => {
        element.classList.add("sidebarContent");
        element.classList.remove("display-none");
      });
    } else {
      setDropdownSelected(prev => (prev !== "" ? "" : prev));
      sidebar?.classList.remove("sidebar_items");
      sidebar?.classList.add("display-none");
      selectedArray.forEach(element => {
        element.classList.remove("sidebarContent");
        element.classList.add("display-none");
      });
      // Remove block property from all dropdown options
      const options = document.getElementsByClassName(
        "dropdown-options"
      ) as HTMLSelectElement;
      const selectedOptionArray = Array.from(options);
      const arrowOptions = document.getElementsByClassName(
        "dropdown-arrow"
      ) as HTMLSelectElement;
      const selectedArrowOptionArray = Array.from(arrowOptions);
      selectedOptionArray.forEach((element, index) => {
        element.classList.remove("display-toggle");
        selectedArrowOptionArray[index].classList.remove("rotated");
      });
    }
  }, [sidebarOpen]);


  // For Mobile View, Toggle DropDown Items
  const handleDropdownToggle = (id: string, optionId: string) => {
    const options = document.getElementsByClassName(
      "dropdown-options"
    ) as HTMLSelectElement;
    const selectedOptionArray = Array.from(options);

    const arrowOptions = document.getElementsByClassName(
      "dropdown-arrow"
    ) as HTMLSelectElement;
    const selectedArrowOptionArray = Array.from(arrowOptions);

    setDropdownSelected(prev => (prev !== id ? id : prev));

    // Remove block property to the sidebar options if un-selected and remove rotated property from the icon

    if (dropdownSelected === id) {
      selectedOptionArray.forEach((element, index) => {
        element.classList.remove("display-toggle");
        selectedArrowOptionArray[index].classList.remove("rotated");
      
      });
      setDropdownSelected(prev => (prev !== "" ? "" : prev));
    } else {
      // Add block property to the sidebar options if selected and add rotated property from the icon
      selectedOptionArray.forEach((element, index) => {
        if (element.id === optionId) {
          element.classList.add("display-toggle");
          selectedArrowOptionArray[index].classList.add("rotated");
         
        } else {
          element.classList.remove("display-toggle");
          selectedArrowOptionArray[index].classList.remove("rotated");
        }
      });
    }
  };

  const closeSideBar = () => {
    setSidebarOpen(false);
    scrollToTop();
  }
  const closeSideBarHomeIcon = () => {
    setSidebarOpen(false);
    scrollToTop();
    setButtonVisible(true);
  }
  // contact modal
  const { isOpen, toggle } = useModal();
  // Contact Button visibility
  const [buttonVisible, setButtonVisible] = useState(true);
  return (
    <div ref={componentRef}>
      <div className="sidebar">
        {/* SideBar Header container */}
        <div className="wmp-img-container">
          <div className="menu-icon-div">
            <img
              src={!sidebarOpen ? menu_icon : cross}
              alt="hamburger_icon"
              className="display-toggle pd-left-10 pd-right-10"
              onClick={() => setSidebarOpen((prev: Boolean) => !prev)}
            ></img>
          </div>
          <Link to= {pageRoutes.Home} onClick={closeSideBarHomeIcon} className="img-size">
            <img src={wmp_logo} alt="wmp logo" className="img-size"></img>
          </Link>
          <div className="nav-contact-btn">
            <Link to= {pageRoutes.Contact} className="link-text-decoration" onClick={closeSideBar}>
              <button className="display-toggle display-void" style={{ visibility: buttonVisible ? 'visible' : 'hidden' }} onClick={() => setButtonVisible(false)}>
                {SIDEBAR_ITEMS.CONTACT_US}
              </button>
            </Link>
          </div>
        </div>

        {/* SideBar Hamberger Icon */}
        <div className="hamburger-icon-container">
          <img
            src={hamburger_icon}
            alt="hamburger_icon"
            className=""
          ></img>
        </div>

        {/* SideBar Screen Items */}
        <div className="sidebar-wrapper display-none" id="sidebarContent">
          <div className="display-none items nav-pd">
            <div
              // className="pd-5"
              id="sidebarItem1"
              onClick={() =>
                handleDropdownToggle("sidebarItem1", "sidebarOptions1")
              }
            >
              <div className="option-li">
                <p className="sidebar_item sidebar_item_mobview">{SIDEBAR_ITEMS.WHO_WE_ARE}</p>
                <img
                  src={dropdown_arrow}
                  alt="arrow"
                  className="dropdown-arrow display-toggle"
                ></img>
              </div>
              <div id="sidebarOptions1" className="dropdown-options">
                <div className="arrow-left display-none"></div>
                <div className="options">
                 <Link to= {pageRoutes.AboutUs} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.ABOUT_US}</p>
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to={pageRoutes.Leadership} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.LEADERSHIP}</p>
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to= {pageRoutes.Team} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.TEAM}</p>
                  </Link>
                </div>
              </div>
            </div>

            <hr className="li-separator"></hr>

            <div
              // className="pd-5"
              id="sidebarItem2"
              onClick={() =>
                handleDropdownToggle("sidebarItem2", "sidebarOptions2")
              }
            >
              <div className="option-li">
                <p className="sidebar_item sidebar_item_mobview">{SIDEBAR_ITEMS.SERVICES}</p>
                <img
                  src={dropdown_arrow}
                  alt="arrow"
                  className="dropdown-arrow display-toggle"
                ></img>
              </div>

              <div id="sidebarOptions2" className="dropdown-options">
                <div className="arrow-left display-none"></div>
                <div className="options">
                  <Link
                    to= {pageRoutes.SoftwareEngineering}
                    className="sidebar_item_option"
                    onClick={closeSideBarHomeIcon}
                  >
                   <p className="line-height-0 margin-bottom">{SIDEBAR_ITEMS.SOFTWARE_ENGINEERING}</p> 
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to= {pageRoutes.DevOps} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                   <p className="margin-bottom">{SIDEBAR_ITEMS.CLOUD_DEVOPS}</p> 
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to= {pageRoutes.QA} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="line-height-0 margin-bottom">{SIDEBAR_ITEMS.QUALITY_ASSURANCE}</p>
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to= {pageRoutes.Designing} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.DESIGNING}</p>
                    </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to={pageRoutes.Operations} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.OPERATIONS}</p>
                  </Link>
                </div>
              </div>
            </div>
            <hr className="li-separator"></hr>

            <div
              // className="pd-5"
              id="sidebarItem3"
              onClick={() =>
                handleDropdownToggle("sidebarItem3", "sidebarOptions3")
              }
            >
              <div className="option-li">
                <p className="sidebar_item sidebar_item_mobview">{SIDEBAR_ITEMS.COMPANY}</p>
                <img
                  src={dropdown_arrow}
                  alt="arrow"
                  className="dropdown-arrow display-toggle"
                ></img>
              </div>
              <div id="sidebarOptions3" className="dropdown-options">
                <div className="arrow-left display-none"></div>
                <div className="options">
                  <Link to={pageRoutes.Blog} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.BLOGS}</p>
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to={pageRoutes.SuccessStories} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                   <p className="margin-bottom">{SIDEBAR_ITEMS.SUCCESS_STORIES}</p> 
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to={pageRoutes.LifeAtWmp} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                    <p className="margin-bottom">{SIDEBAR_ITEMS.LIFE_AT_WMP}</p>
                  </Link>
                  <hr className="options-separator display-none"></hr>
                  <Link to={pageRoutes.Awards} onClick={closeSideBarHomeIcon} className="sidebar_item_option">
                   <p className="margin-bottom">{SIDEBAR_ITEMS.AWARDS}</p> 
                  </Link>
                </div>
              </div>
            </div>


            {/* For future refrence */}
            <hr className="li-separator"></hr>

            {/* SideBar Screen Items */}
            {/*  For future refrence  */}
            <div
            // className="pd-5"
            id="sidebarItem4"
            // onClick={() =>
            //   handleDropdownToggle("sidebarItem4", "sidebarOptions4")
            // }
          >
            <div className="option-li ">
              <Link to={pageRoutes.Solutions} onClick={closeSideBarHomeIcon} className="link-text-decoration">
              <p className="sidebar_item sidebar_item_mobview">{SIDEBAR_ITEMS.SOLUTIONS}</p>
              </Link>
              {/* For future references */}
              {/* <img
                src={dropdown_arrow}
                alt="arrow"
                className="dropdown-arrow display-toggle"
              ></img> */}
            </div>
            {/* <div id="sidebarOptions4" className="dropdown-options">
              <div className="arrow-left display-none"></div>
              <div className="options">
                <p className="sidebar_item_option">{SIDEBAR_ITEMS.TRAKWA}</p>
                <hr className="options-separator display-none"></hr>
                <p className="sidebar_item_option">{SIDEBAR_ITEMS.QUFREE}</p>
                <hr className="options-separator display-none"></hr>
                <p className="sidebar_item_option">{SIDEBAR_ITEMS.CXOTULZ}</p>
              </div>
            </div> */}
          </div>
          

            <hr className="li-separator"></hr>
            
            <div className="option-li display-none" id="sidebarItem5">
              <><p className="sidebar_item sidebar_item_mobview" onClick={toggle}>
                {SIDEBAR_ITEMS.CONTACT_US}
              </p>
                <Modal isOpen={isOpen} toggle={toggle}></Modal></>
            </div>
            {/* <hr className="li-separator display-toggle"></hr> */}
          </div>

          {/* Footer of SideBar */}
          <div className="">
            <div className="sidebar_footer">
              <hr className="separator display-none"></hr>
              <a href={SOCIAL_LOGIN_ENDPOINT.LINKEDIN} target="_blank" rel="noreferrer" className="sidebar_footer_img">
                <img
                  src={IMAGES.NAV_FOOTER_LINKDIN}
                  alt="linkedin"
                ></img>
              </a>
              <a href={SOCIAL_LOGIN_ENDPOINT.INSTAGRAM} target="_blank" rel="noreferrer" className="sidebar_footer_img">
                <img
                  src={IMAGES.NAV_FOOTER_INSTAGRAM}
                  alt="instagram"
                ></img>
              </a>
              <a href={SOCIAL_LOGIN_ENDPOINT.FACEBOOK} target="_blank" rel="noreferrer" className="sidebar_footer_img">
                <img
                  src={IMAGES.NAV_FOOTER_FACEBOOK}
                  alt="facebook"
                ></img>
              </a>
            </div>
          </div>
        </div>

        <hr className="li-separator display-toggle"></hr>
      </div>
    </div>
  );
}

export default Sidebar;
