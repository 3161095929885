import React from 'react';
import './style.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

interface Props {
    storyData: { imgWeb: string; imgMob: string; overview: string; challenges: { heading: string; desc: string[]; pointer: boolean; }[]; services: { heading: string; desc: string[]; pointer: boolean; }[]; results: { heading: string; desc: string[]; pointer: boolean; }[]; }
}

const StoryDescription = ({ storyData }: Props) => {
    const location = useLocation();
    const currentPage = location.pathname;
    const isNGOPage = currentPage === pageRoutes.NonGovOrg;
    return (
        <div>
            <img alt="success-story" src={storyData.imgMob} className='mob-img' />

            <div className='main-container-stories'>
                <LazyLoadImage
                    className='web-img'
                    src={storyData.imgWeb}
                    alt='network'
                />

                {/* Client Overview */}
                <h2>Client Overview</h2>
                <div className='main-div'>
                    <p>{storyData.overview}</p>
                </div>

                {/* Challenges Faced */}
                <h2>Challenges Faced</h2>

                <div className='main-div'>
                    {storyData.challenges.map(({ heading, desc, pointer }, index) => (
                        <div key={index} className='inner-div'>
                            <h4 className={!pointer ? 'display-initial' : ''}>{heading}</h4>
                            {pointer ? (
                                <ul className="filled-circle-list">
                                    {desc.map((sentence, i) => (
                                        <li key={i} className="filled-circle">{sentence}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className='display-initial'>{desc.join(' ')}</p>
                            )}
                        </div>
                    ))}
                </div>

                {/* Services Rendered */}
                <h2>{isNGOPage ? 'Services Rendered and Results Achieved: ' : 'Services Rendered'}</h2>
                <div className='main-div'>
                    {storyData.services.map(({ heading, desc, pointer }, index) => (
                        <div key={index} className='inner-div'>
                            <h4 className={!pointer ? 'display-initial' : ''}>{heading}</h4>
                            {pointer ? (
                                <ul className="filled-circle-list">
                                    {desc.map((sentence, i) => (
                                        <li key={i} className="filled-circle">{sentence}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p className='display-initial'>{desc.join(' ')}</p>
                            )}
                        </div>
                    ))}
                </div>

                {/* Results Achieved */}
                {storyData.results && storyData.results.length > 0 && (
                    <div>
                        <h2>{isNGOPage ? null : 'Results Achieved:'}</h2>
                        <div className='main-div'>
                            {storyData.results.map(({ heading, desc, pointer }, index) => (
                                <div key={index} className='inner-div'>
                                    <h4 className={!pointer ? 'display-initial' : ''}>{heading}</h4>
                                    {pointer ? (
                                        <ul className="filled-circle-list">
                                            {desc.map((sentence, i) => (
                                                <li key={i} className="filled-circle">{sentence}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p className='display-initial'>{desc.join(' ')}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Common Footer */}
                <CommonFooter />
            </div>
        </div>
    );
};

const CommonFooter = () => {
    const location = useLocation();
    const currentPage = location.pathname;

    return (
        <>
            {currentPage === pageRoutes.Hospitality ? (
                <h2 className='story-footer'>Wingman Partners – Your Trusted Technology Partner in Hospitality Excellence !</h2>
            ) : currentPage === pageRoutes.Healthcare ? (
                <h2 className='story-footer'>Wingman Partners – Transforming Healthcare with Precision Data Insights !</h2>
            ) :currentPage === pageRoutes.Transportation ? (
                <h2 className='story-footer'>Wingman Partners – Your Innovation Ally in Shaping the Future !</h2>
            ) :currentPage === pageRoutes.TechSpaces ? (
                <h2 className='story-footer'>Wingman Partners – Your Strategic Ally in Transforming Commercial Spaces !</h2>
            ) :currentPage === pageRoutes.EmpCommunity ? (
                <h2 className='story-footer'>Wingman Partners – Your Catalyst in Empowering Work-Life Happiness !</h2>
            ) :currentPage === pageRoutes.KitchenDining ? (
                <h2 className='story-footer'>Wingman Partners – Your Strategic Ally in Elevating Global Connectivity !</h2>
            ) :currentPage === pageRoutes.NonGovOrg ? (
                <h2 className='story-footer'>Wingman Partners – Your ally for NGO</h2>
            ) :
            (
                <>
                    <p className='main-div'>At Wingman Partners, we're not just service providers; we're strategic partners committed to the success of our clients. This success story is a testament to our expertise, dedication, and collaborative approach.</p>
                    <h2 className='story-footer'>Wingman Partners – Your Success, Our Mission!</h2>
                </>
            )}
        </>
    );
};

export default StoryDescription;
