import React, { useEffect } from 'react'
import { images, cardData, technologyImages, carouselTechOptions, scrollToTop } from 'src/shared/utils/appConstant';
import './style.scss';
import 'bootstrap/dist/css/bootstrap.css'
import HomePageFooter from '../home/components/Footer';
import Footer from "src/components/footer";
import Cards from 'src/components/cards';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles
import backgroundImage from '../../shared/assets/images/se-bg.png';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const SoftwareEngineering = () => {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const target = entry.target as HTMLDivElement;
            const imageUrl = target.getAttribute('data-background-image');
            if (imageUrl) {
              target.style.backgroundImage = `url(${imageUrl})`;
            }
            observer.unobserve(target);
          }
        });
      },
    );

    document.querySelectorAll('[data-background-image]').forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className='page-container-SE drop-in'>
        <div className='image-container' data-background-image={backgroundImage}>
          <LazyLoadImage
            alt="software-background"
            effect="blur"
            wrapperClassName="background-image-placeholder"
          />
          <div className="text-container">
            <h1 className='heading drop-in'>Software Engineering</h1>
            <p className='heading-desc display-none drop-in-2'>At the intersection of creativity and precision, our team crafts the digital future, engineering robust, scalable solutions that redefine possibilities and elevate user engagement.</p>
          </div>
          <p className='breadcrumb position-abs drop-in-2'>
            <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <Link className='breadcrumb-link' to={pageRoutes.Services}>Services</Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link className='breadcrumb-link' to={pageRoutes.SoftwareEngineering} onClick={scrollToTop}>Software Engineering</Link>
            </p>
          </p>
          <div className='red-bg-box'></div>
        </div>
        <div className="about-our-service ">
          <div className="left-section animate__animated animate__fadeInLeft animate__delay-1s ">
            <h5>About Our Service</h5>
            <h2>Software Engineering</h2>
            <h3>About Our Service</h3>
            <p>
              Crafting code that transcends functionality, our software engineers architect solutions that stand the test of innovation, delivering excellence with every line.
              <img alt="blue-box" className='blue-box' src={images.dark_blue_box} />
              <img alt="red-box" className='red-box' src={images.red_box} />
              <div className='red-box-sq'></div>
            </p>
            <img alt="blue-box" src={images.blue_box} />
          </div>
          <div className="right-section">
            <LazyLoadImage
              src={images.SE_Img}
              alt='software'
              className='animate__animated animate__fadeInRight animate__delay-1s'
            />
          </div>
          <img alt="blue-box" src={images.blue_box} className='blue-box-bg-SE' />
          <img alt="circle-img" src={images.circles} className='circles-SE' />
        </div>
        <div className="lower-div">
          <h2>What We Offer</h2>
          <Cards cardData={cardData} />

          <div className="technologies">
            <h2>Technologies</h2>
            <div className="images-Tech">
              {technologyImages.map((technology, index) => (
                <div key={index} className="image-wrapper">
                  <LazyLoadImage
                    className='hover-effect'
                    effect='blur'
                    src={technology.url}
                    alt='technology'
                  />
                  <div className="text-overlay">{technology.name}</div>
                </div>
              ))}
            </div>
            <div className="owl-carousel-mob">
              <OwlCarousel {...carouselTechOptions}>
                {technologyImages.map((URL) => (
                  <div>
                    <img alt="technology-img" src={URL.url} />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
        <HomePageFooter page="" />
        <Footer />
      </div>
    </>
  )
}

export default SoftwareEngineering