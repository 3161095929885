import React from 'react'
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

interface Props {
    text: string,
}

const BreadcrumbDevOps = ({text}: Props) => {
  return (
    <p className='breadcrumb position-abs'>
        <Link className='breadcrumb-link' to = {pageRoutes.Home} onClick={scrollToTop}>Home</Link>
        <span className="breadcrumb-separator"> / </span>
        <Link className='breadcrumb-link' to = {pageRoutes.Services}>Services</Link>
        <span className="breadcrumb-separator"> / </span>
        <Link className='breadcrumb-link' to = {pageRoutes.DevOps} onClick={scrollToTop}>Cloud & DevOps</Link>
        <span className="breadcrumb-separator"> / </span>
        <p className='fw-light' onClick={scrollToTop}>{text}</p>
    </p>
  )
}

export default BreadcrumbDevOps