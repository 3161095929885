import React, { useState, useEffect } from "react";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
import { IMAGES } from "src/shared/utils/appConstant";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Card from "./components/Flipcards";
import PopModal from "./components/Popcard";
import Footer from "src/components/footer";
import { cardsData } from "src/shared/utils/string";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/shared/utils/appConstant";
import useModal from "src/shared/hooks/useModal";
import Modal from "src/components/contact/components/modal";
import Button from "src/components/button";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "font-awesome/css/font-awesome.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the desired effect styles
import backgroundImage from "../../shared/assets/images/about-us-bg.jpg";
import 'animate.css';
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

const images = [IMAGES.CERTIFICATE, IMAGES.AWARDS_IMG];

function AboutUs() {
  //data set for cards
  const { isOpen, toggle } = useModal();
  const [index, setIndex] = React.useState(0);
  const handleCarouselChange = (currentIndex: number): void => {
    // Check if the last image is reached
    if (currentIndex === images.length) {
      // If it's the last image, reset to the first image
      setIndex(0);
    } else {
      setIndex(currentIndex);
    }
  };
  // Carousel button function
  const [prevButtonEnable, setprevButtonEnable] = useState(false);
  const [nextButtonEnable, setnextButtonEnable] = useState(true);
  useEffect(() => {
    if (index === 0) {
      setprevButtonEnable(false);
      setnextButtonEnable(true);
    } else if (index === images.length - 1) {
      setprevButtonEnable(true);
      setnextButtonEnable(false);
    } else {
      setprevButtonEnable(true);
      setnextButtonEnable(true);
    }
  }, [index]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const target = entry.target as HTMLDivElement;
          const imageUrl = target.getAttribute("data-background-image");
          if (imageUrl) {
            target.style.backgroundImage = `url(${imageUrl})`;
          }
          observer.unobserve(target);
        }
      });
    });

    document.querySelectorAll("[data-background-image]").forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  //Contact us page
  //ended
  return (
    <>
      <div className="about-us">
        <div
          className="image-container position-rel"
          data-background-image={backgroundImage}
        >
          <LazyLoadImage
            alt="awards-background"
            effect="blur"
            wrapperClassName="background-image-placeholder"
          />
          <div className="text-container">
            <h1 className="heading drop-in">About Us</h1>
            <p className="display-block display-none heading-desc drop-in-2">
              
            Wingman Partners: Where Dreams Soar, Challenges Are Conquered, and Innovation Thrives. 
            We are a forward-thinking, cloud-first company, specializing in Development, DevOps , IT infrastructure services, and innovative cloud solutions.
            </p>
          </div>
          <div className="position-abs breadcrumb drop-in-2">
            <Link to={pageRoutes.Home} onClick={scrollToTop}>
              Home
            </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link to={pageRoutes.AboutUs} onClick={scrollToTop}>
                About Us
              </Link>
            </p>
          </div>

          <div className="red-bg-box"></div>
        </div>
        {/* //About section ended here
            //Out story section started here */}
        <div className="our-story">
          <div className="our-story-content">
            <h2 className="os-heading">Our Story</h2>
            <p className="">
              Wingman Partners isn't merely a business concept; it's the
              realization of a dream nurtured by our founder, Ashav Kaushik. Our
              journey commenced in 2019, taking form as senior management
              recognized the potential in Ashav's vision to reshape the software
              landscape. With a keen focus on the cloud domain and guided by the
              principles of Scalability, Security, and Savings (3S), they became
              an integral part of the team.
            </p>
            <div className="os-image-mv position-rel display-toggle">
              <img src={IMAGES.ABOUT_MOB_ASHAV} alt="ashav" />{" "}
              {/* For mobile view */}
            </div>
            <p className="os-para2-mob">
              From there, Wingman has grown into a close-knit team of over 50
              dedicated individuals. But this journey wasn't always smooth sailing. 
              In 2019, we were stabilizing when the unexpected hit - the COVID-19 pandemic. 
              Like many others, it was a challenging time. 
              However, it was our team's unwavering determination, resilience, and all our 
              partners faith in our process that allowed Wingman to weather the storm and continue moving forward. 
            </p>
          </div>
          <div className="our-story-images">
            <div className="row os-content">
              <div className="col-md-4">
                <LazyLoadImage
                  src={IMAGES.OS_ASHAV_IMG}
                  className="os-image animate__backInLeft"
                  alt="ashav"
                />
              </div>
              <div className="col-md-8">
                <div className="row ">
                  <div className="col-md-6">
                    <LazyLoadImage
                      src={IMAGES.WELCOME_KIT}
                      className="os-image drop-in-2"
                      alt="welcome-kit"
                    />
                  </div>
                  <div className="col-md-6">
                    <LazyLoadImage
                      src={IMAGES.TEAM_IMG}
                      className="os-image drop-in-2"
                      alt="team"
                    />
                  </div>
                </div>
                <div className="row team-img2 display-block">
                  <LazyLoadImage
                    src={IMAGES.TEAM_IMG1}
                    className="os-image drop-in-2"
                    alt="team"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* mobile view  */}
          <div className="os-mob display-toggle ">
            <div className="row os-mob-view image-grid">
              <div className="row os-mob-view display-toggle">
                <div className="col-md-12 os-mob-col ">
                  <div className="os-image-blue position-rel">
                    <img
                      className="os-image-mob os-image-team1 display-toggle position-rel"
                      src={IMAGES.TEAM_IMG1}
                      alt="team"
                    />
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 os-mob-col">
                  <div className="os-image-red display-rel">
                    <img
                      className="os-image-mob display-toggle position-rel img-size"
                      src={IMAGES.WELCOME_KIT}
                      alt="welcome-kit"
                    />
                  </div>
                </div>
                <div className="col-md-6 os-mob-col">
                  <img
                    className="os-image-mob display-toggle position-rel img-size"
                    src={IMAGES.TEAM_IMG}
                    alt="team"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="our-story-content">
            <p className="os-para-3">
              We've faced and overcome various obstacles to carve out our unique
              identity in the market. Our expertise lies in building cloud and
              IoT-based products from the ground up. We're there to assist you
              in choosing the right technology stack, establishing code quality
              standards, and conducting essential proof of concepts. As part of
              our portfolio, we can even help you set up a NOC team, offering
              education and guidance on the importance of code and
              infrastructure security.
            </p>
            <p className="os-para-4">
              At Wingman Partners, we're more than just a business; we're a team
              of passionate individuals who believe in the power of dreams and
              innovation. We're here to be your trusted partner in your journey
              to success.
            </p>
          </div>
        </div>

        {/* //core values section started from here */}
        <div className="core-values ">
          <div className="web-view">
            <div className="row core-value-content">
              <div className="col-md-6">
              <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
                <span className="cv-heading">Core Values</span>
                <h1 className="cv-text-stroke position-rel">Mission</h1>
                <p className="box-content">
                  Our mission is to empower individuals with the brilliance of
                  process knowledge and diversified skill set excellence to
                  bridge the gap between customer expectation and service
                  delivery.
                </p>
              </AnimationOnScroll>  
              </div>
              <div className="col-md-6 cv-mission-img animate__animated animate__backInRight">
              <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
                <LazyLoadImage
                  src={IMAGES.MISSION_IMG}
                  className="cv-image"
                  alt="mission"
                />
               </AnimationOnScroll> 
              </div>
            </div>
            <div className="row core-value-content">
              <div className="col-md-6">
              <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
                <LazyLoadImage
                  src={IMAGES.ROBO_IMG}
                  className="cv-image"
                  alt="robo"
                />
              </AnimationOnScroll>  
              </div>
              <div className="col-md-6 core-content-box">
              <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
                <span className="cv-heading">Core Values</span>
                <h1 className="cv-text-stroke">Vision</h1>
                <p className="box-content ">
                  Build an organization of complete transparency providing
                  growth opportunities aligned to an individual’s interest and
                  excellence.
                </p>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          {/* mobile view for core values section started from here */}
          <div className="row cv-mob-view">
            <div className="row cv-mob-header">
              <div className="col-md-12">
                <p>Core Values</p>
                <div className="cv-header-name">
                  <div>
                    <h1 className="cv-text-stroke">Mission</h1>
                  </div>
                  <div className="cv-mob-bg position-abs">
                    <img
                      className="position-abs"
                      src={IMAGES.DOT_CIRCLES}
                      alt="dot"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" cv-mob-content position-rel">
              <div className="cv-image-mob position-rel ">
                <img src={IMAGES.CORE_FIREWORK_IMG} alt="mission" />
              </div>
              <h5 className="cv-overlay-text position-abs">
                Our mission is to empower individuals with the brilliance of
                processknowledge and diversified skill set excellence to bridge
                the gap betweencustomer expectation and service delivery.
              </h5>
            </div>
          </div>
          <div className="row cv-mob-view ">
            <div className="row cv-mob-header">
              <div className="col-md-12">
                <div className="cv-header-name">
                  <div>
                    <h1 className="cv-text-stroke">Vision</h1>
                  </div>
                  <div className="cv-mob-bg position-abs">
                    <img
                      className="position-abs"
                      src={IMAGES.DOT_CIRCLES}
                      alt="dot"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" cv-mob-content vision-image-mob position-rel">
              <div className="cv-image-mob position-rel">
                <img src={IMAGES.CORE_ROBO_IMG} alt="robo" />
              </div>
              <h5 className="cv-overlay-text position-abs">
                Build an organization of complete transparency providing growth
                opportunities aligned to an individual’s interest and
                excellence.
              </h5>
            </div>
          </div>
        </div>

        {/* //core values section ended here
            //make us different section started here  */}
        <div className="mkd-bg">
          <div className="make-us-different pd-left-8">
            <div className="mkd-content">
              <h2>What make us different?</h2>
              <p className="box-content">
              Elevate your business with Wingman Partners, a leading Product Development Services firm specializing in SaaS, Cloud Solutions, and seamless integrations. 
              As your go-to partner, we offer tailored solutions, a top-notch team for cost-effective, quality-driven results, and tech-savvy innovations. Benefit from 
              dedicated resource allocation, personalized for your project needs, flexible payment, execution, and centralized support through our singular platform. 
              We prioritize meticulous requirement scrutiny. Trust in our commitment to quality and timely deliveries. Join hands with us today for a trusted partnership, 
              experiencing excellence and comprehensive services on your journey to success.
              </p>
            </div>
            <div className="mkd-btn">
              {window.innerWidth < 768 ? (
                <Link to="/contact" onClick={scrollToTop}>
                  <Button>Contact Us</Button>
                </Link>
              ) : (
                <>
                  <Button onClick={toggle}>Contact Us</Button>
                  <Modal isOpen={isOpen} toggle={toggle}></Modal>
                </>
              )}

              {/* Render the Contact component with the modal */}
              {/* <Contact isOpen={isModalOpen} toggle={toggleModal} /> */}
            </div>
            <div className="main-container">
              {cardsData.map(cardData => (
                <Card key={cardData.index} {...cardData} />
              ))}
            </div>
            <div className="card-mv">
              <div className="modal-container">
                {cardsData.map(cardData => (
                  <PopModal key={cardData.index} {...cardData} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* //make us diffrent section ended here
            //awards section started here */}
        <div className="awards pd-left-8">
          <div className="row awards-container">
            <div className="col-md-7 awards-col">
            <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
              <h3 className="awards-heading">Awards & Recognition</h3>
              {/* mobile view */}
              <div className="row cv-mob-header">
                <div className="col-md-12">
                  <div className="cv-header-name">
                    <div>
                      <h1 className="cv-text-stroke awards-mv">
                        Awards & Recognition
                      </h1>
                    </div>
                    <div className="cv-mob-bg awards-mv-bg position-abs display-toggle">
                      <img
                        className="position-abs"
                        src={IMAGES.DOT_CIRCLES}
                        alt="dot"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="awards-content">
                Recognizing Excellence & Celebrating Achievements Through Awards
              </p>
              <div className="case-study-round-btn display-none">
                <button
                  className={`btn rounded-circle btn-lg ${
                    prevButtonEnable
                      ? "carousel-btn carousel-prev enableButton"
                      : "carousel-btn disableButton"
                  }`}
                  disabled={!prevButtonEnable}

                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => setIndex(Math.max(0, index - 1))}
                  />
                </button>
                <button
                  className={`btn rounded-circle btn-lg ${
                    nextButtonEnable
                      ? "carousel-btn carousel-next enableButton"
                      : "carousel-btn disableButton"
                  }`}
                  disabled={!nextButtonEnable}

                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    onClick={() =>
                      setIndex(Math.min(images.length - 1, index + 1))
                    }
                  />
                </button>
              </div>
              </AnimationOnScroll>
            </div>
            {/* carousel animation for awards section*/}
            <div className="col-md-5 awards-img">
              <div className="slideshow">
              <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
                <Carousel
                  className="slideshowSlider"
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={3000}
                  showArrows={false}
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                  selectedItem={index}
                  onChange={handleCarouselChange}
                >
                  {images.map((URL, index) => (
                    <div className="slide" key={index}>
                      <LazyLoadImage src={URL} alt="awards" />
                    </div>
                  ))}
                </Carousel>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
        {/* //awards section ended here
            // know us better section started from here */}
        <div className="about-kub pd-left-8">
          <div className="kub-header">
            <h1>Get To Know Us Better</h1>
          </div>
          <div>
            <Link to="/leadership">
              <Button onClick={scrollToTop}>Leadership</Button>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default AboutUs;
