import React, { useEffect, useState } from 'react';
import './style.scss';
import { IMAGES, TWO_THOUSAND_CONST } from 'src/shared/utils/appConstant';
import { CONTACT_API_URL, isValidEmail, isValidPhone, isValidName, MAX_MESSAGE_LENGTH } from 'src/shared/utils/string';
import SuccessFullModal from 'src/components/contact/successfullModal';

interface IContactForm {
  name: string;
  email: string;
  phone: string;
  message: string;
  "formId": "wmp-contact-form-v1",
}

const ContactForm: React.FC = () => {
  const [contact, setContact] = useState<IContactForm>({
    name: '',
    email: '',
    phone: '',
    message: '',
    "formId": "wmp-contact-form-v1",
  });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const interval = setTimeout(() => {
      // Increment the activeIndex to move to the next slide
      setShowModal(false);
      setContact({
        name: '',
        email: '',
        phone: '',
        message: '',
        "formId": "wmp-contact-form-v1",
      })
    }, TWO_THOUSAND_CONST); // Close the modal after 2 seconds

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(interval);
  }, [showModal]);


  const [errors, setErrors] = useState<Record<string, boolean>>({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
    setContact({ ...contact, [name]: value });
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false}));
    }
    setContact({ ...contact, [name]: value });
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false}));
    }
    setContact({ ...contact, [name]: value });
  };

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validateForm();

    if (isFormValid()) {
      try {
        const response = await fetch(CONTACT_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(contact),
        });
        console.log("🚀 ~ handleSubmit ~ response:", response.status)
        if (response.status === 200) {
          setShowModal(true);
          console.log('Form submitted successfully');
        } else {
          console.error('Failed to submit the form');
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
      }
    }
  };

  const validateForm = () => {
    const { name, email, message, phone } = contact;
    const messageValid = message.trim().length <= MAX_MESSAGE_LENGTH;

    setErrors({
      name: !isValidName(name),
      email: !isValidEmail(email),
      phone: !isValidPhone(phone),
      message: !messageValid,
    });
  };

  const validateName = () => {
    setErrors((prevErrors) => {
      const { name } = contact;
      return {
        ...prevErrors,
        name: name.length > 0 ? !isValidName(name) : false,
      };
    });
  }

  const validateEmail = () => {
    setErrors((prevErrors) => {
      const { email } = contact;
      return {
        ...prevErrors,
        email: email.length > 0 ? !isValidEmail(email) : false,
      };
    });
  }

  const validatePhone = () => {
    setErrors((prevErrors) => {
      const { phone } = contact;
      return {
        ...prevErrors,
        phone: phone.length > 0 ? !isValidPhone(phone) : false,
      };
    });
  }

  const isFormValid = () => {
    return Object.values(errors).every((error) => !error);
  };

  return (
    <><div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className='input-container'>
          <img className="input-icon" src={IMAGES.USER_ICON} alt="icon" />
          <input
            type="text"
            name="name"
            value={contact.name}
            onChange={handleChangeName}
            placeholder='Full Name*'
            className={`contact-input`}
            onBlur={validateName}
            required />
          {/* {errors.name && <div className='notched-div bottom'>Invalid name</div>} */}
          {errors.name && <p className="error-popup">Invalid Name</p>}
        </div>
        <div className='input-container'>
          <img className="input-icon mail-img" src={IMAGES.MAIL_CONTACT_ICON} alt="icon" />
          <input
            type="email"
            name="email"
            value={contact.email}
            onChange={handleChangeEmail}
            placeholder='Email Address*'
            className={`contact-input`}
            onBlur={validateEmail}
            required />
          {/* {errors.email && <div className='notched-div bottom'>Invalid Email</div>} */}
          {errors.email && <p className="error-popup">Invalid Email</p>}
        </div>
        <div className='input-container'>
          <img className="input-icon phone-img" src={IMAGES.PHONE_CONTACT_ICON} alt="icon" />
          <input
            type="number"
            name="phone"
            value={contact.phone}
            placeholder='Contact Number*'
            onChange={handleChangePhone}
            className={`contact-input`}
            onBlur={validatePhone}
            required />
          {/* {errors.email && <div className='notched-div bottom'>Invalid Number</div>} */}
          {errors.phone && <p className="error-popup">Invalid Number</p>}
        </div>
        <div className='input-container'>
          <textarea
            name="message"
            value={contact.message}
            onChange={handleChangeMessage}
            placeholder='Message'
            className={`contact-textarea`} />
        </div>
        <button className='contact-btn' type='submit'>Submit</button>
      </form>
    </div>
      {showModal && <SuccessFullModal />}
    </>

  );
};

export default ContactForm;