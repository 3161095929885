import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { scrollToTop, images } from 'src/shared/utils/appConstant';


interface Props {
    cardData: {
        URL: string;
        heading: string;
        desc: string;
        routeLink: string;
    }[]
}
const AnimationCards = ({ cardData }: Props) => {
    return (
        <div className='container-cards'>
            {cardData.map((card, index) => (
                <div className='animated-card' key={index}>
                    <div className="animated-card-bg" style={{ backgroundImage: `url(${card.URL})` }}>
                        <div className='opaque-bg'>
                            <p className='heading'>{card.heading}</p>
                            <p className='desc'>{card.desc}</p>
                            <Link to={card.routeLink} onClick={scrollToTop} key={index}>
                                <div className='read-now-btn'>
                                    <p>READ NOW</p>
                                    <img className='arrow arrow-height' src={images.RIGHT_ARROW} alt='arrow-btn'></img>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AnimationCards