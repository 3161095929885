import React from 'react';
import Breadcrumb from '../Breadcrumb';
import './style.scss';
import CardsServices from 'src/views/SoftwareEngineering/components/CardsServices';
import { support } from 'src/shared/utils/string';
import FooterServices from 'src/views/SoftwareEngineering/components/FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';

const Support = () => {
  return (
    <div className="page-container-support">
        <div className='image-container'>
          <div className='text-container drop-in-2'>
            <h1 className='heading'>Level 3 Support (L3: 24x7)</h1>
          </div>
            <Link to="/l3-support" className='no-decoration '><Breadcrumb text={"Level 3 Support (L3: 24x7)"} /></Link>  
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices={support} />
        <FooterServices />
          <Footer />
    </div>
  )
}

export default Support