import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { IMAGES, scrollToTop } from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import { cardCelebrationData } from "src/shared/utils/string";
import { cardRhythmData } from "src/shared/utils/string";
import { cultureMvData } from "src/shared/utils/string";
import PhotoAlbum from "react-photo-album";
import CarouselAlbum from "./carouselAlbum";
import { CarouselAlbum1 } from "./carouselAlbum";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'animate.css';
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

const LifeAtWmp = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const threshold = windowWidth < 600 ? 6 : 9;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="life-at-wmp-container">
      <div className="life-at-wmp-image-container image-container position-rel">
        <div className="text-container">
          <h1 className="heading drop-in">Life At Wingman</h1>
          <p className="heading-desc display-block display-none drop-in-2">
            Experience an enjoyable work environment where our team takes
            precedence, fostering continuous motivation.
          </p>
        </div>
        <div className="breadcrumb position-abs drop-in-2">
          <Link to={pageRoutes.Home} onClick={scrollToTop}>Home</Link>
          <span className="breadcrumb-separator"> / </span>
          <p className="fw-light">
            <Link to={pageRoutes.LifeAtWmp}>Life At Wingman</Link>
          </p>
        </div>
        <div className="red-bg-box"></div>
      </div>
      <div className="our-culture-container ">
        <div className="our-culture-heading drop-in">
          <h2>Our Culture</h2>
        </div>
        <div className="our-culture-desc drop-in-2">
          <p>
            Wingman places its culture at the forefront, where our daily rhythm
            not only motivates but also retains our valued team members.
          </p>
        </div>
        <div className="row culture-people-container display-none">
          <div className="row culture-people">
            <div className="col-md-7 culture-content animate__animated animate__fadeInLeft animate__delay-1s">
              <div className="culture-content-header">
                <p>People Centric Culture</p>
              </div>
              <div className="culture-para">
                <p>
                  Our people are our top priority. We invest in their growth and
                  skill development, fostering a safe and creative work
                  environment.
                </p>
              </div>
            </div>
            <div className="col-md-5 culture-content-img animate__animated animate__fadeInRight animate__delay-1s">
            <LazyLoadImage
                    className='culture-right-img'
                    src={IMAGES.CULTURE_PEOPLE}
                    alt='people'
                />
            </div>
          </div>
          <div className="row culture-people">
            <div className="col-md-5 culture-content-img">
            <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
            <LazyLoadImage
                    className='culture-left-img'
                    src={IMAGES.CULTURE_INNOVATION}
                    alt='people'
                />
            </AnimationOnScroll>
            </div>
            <div className="col-md-7 culture-content culture-content-right">
            <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
              <div className="culture-content-header">
                <p>Curiosity and Innovation</p>
              </div>
              <div className="culture-para">
                <p>
                  We approach challenges with curiosity, encouraging a mindset
                  of continuous learning, critical thinking, and creative
                  problem-solving.{" "}
                </p>
              </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="row culture-people">
            <div className="col-md-7 culture-content">
            <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
              <div className="culture-content-header">
                <p>Building for Tomorrow</p>
              </div>
              <div className="culture-para">
                <p>
                  We are dedicated to creating a meaningful future. We resolve
                  problems creatively, accept risks, and embrace experimentation
                  for long-term success.{" "}
                </p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-5 culture-content-img">
            <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
            <LazyLoadImage
                    className='culture-right-img'
                    src={IMAGES.CULTURE_BUILDING}
                    alt='people'
                />
            </AnimationOnScroll>
            </div>
          </div>
          <div className="row culture-people">
            <div className="col-md-5 culture-content-img">
            <AnimationOnScroll animateIn="animate__fadeInLeft" offset={150} animateOnce= {true}>
            <LazyLoadImage
                    className='culture-left-img'
                    src={IMAGES.CULTURE_FAMILY}
                    alt='people'
                />
            </AnimationOnScroll>    
            </div>
            <div className="col-md-7 culture-content culture-content-right">
            <AnimationOnScroll animateIn="animate__fadeInRight" offset={150} animateOnce= {true}>
              <div className="culture-content-header">
                <p>Unified family and Growth</p>
              </div>
              <div className="culture-para">
                <p>
                  We operate as one family, respecting and caring for each
                  other. Our growth involves teamwork, responsibility, and a
                  commitment to delivering excellence.
                </p>
              </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
      <div className="culture-mv-container display-toggle">
        {cultureMvData.map((value) => (
          <div className="culture-mob-content">
            <div className="culture-header-mv">
              <div>
                <h1 className="culture-text-stroke ">{value.headerName}</h1>
              </div>
              <div className="culture-mob-bg position-abs">
                <img className="position-abs" src={IMAGES.DOT_CIRCLES} alt="dot" />
              </div>
            </div>
            <div className="culture-mob-image">
              <img src={value.cultureImage} alt="culture" />
            </div>
            <div className="culture-mob-para">
              <p>{value.culturePara}</p>
            </div>
          </div>
        ))};
      </div>
      <div className="celebration-container">
        <div className="our-culture-heading">
          <h2>Celebrations At Wingman</h2>
        </div>
        <div className="our-culture-desc">
          <p>
            Discover the festive spirit at Wingman Partners – where every moment
            is a celebration, and unity is the essence of our success and
            happiness.{" "}
          </p>
        </div>
        <div className="culture-card-row">
          <PhotoAlbum
            layout="rows"
            photos={cardCelebrationData.slice(0, threshold).map((value, index) => ({
              src: value.celebrationImage,
              width: value.width,
              height: value.height,
              key: index.toString(),
            }))}
          />
        </div>
        <div className="see-all-btn">
          <CarouselAlbum />
        </div>

      </div>
      <div className="celebration-container rhythm-container">
        <div className="our-culture-heading">
          <h2>Wingman’s Daily Rhythm</h2>
        </div>
        <div className="our-culture-desc">
          <p>
            Explore the dynamic blend of work and play at Wingman Partners in these snapshots, showcasing the energetic rhythm on a daily and fun activities that keep our team members engaged.{" "}
          </p>
        </div>
        <div className="culture-card-row">
          <PhotoAlbum
            layout="rows"
            photos={cardRhythmData.slice(0, threshold).map((value, index) => ({
              src: value.celebrationImage,
              width: value.width,
              height: value.height,
              key: index.toString(),
            }))}
          />
        </div>
        <div className="see-all-btn">
          <CarouselAlbum1 />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
export default LifeAtWmp;
