import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom'
import { cardDataDevOps, images, teamDevopsImages, devopsExpertiseImages, scrollToTop } from 'src/shared/utils/appConstant';
import HomePageFooter from '../home/components/Footer';
import Footer from "src/components/footer";
import Cards from 'src/components/cards';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Import the desired effect styles
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const DevOps = () => {
  return (
    <>
      <div className='page-container-DevOps'>
        <div className='image-container'>
          <div className="text-container">
            <h1 className='heading drop-in'>Cloud & DevOps</h1>
            <p className='heading-desc display-none drop-in-2'>Elevate your Cloud lifecycle with DevOps, a dynamic approach harmonizing coding and operations for rapid, reliable, and resilient results. Unleash the power of continuous integration and delivery, fostering a seamless development pipeline that accelerates innovation.</p>
          </div>
          <p className='breadcrumb position-abs drop-in-2'>
            <Link className='breadcrumb-link' to={pageRoutes.Home} onClick={scrollToTop}>Home </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="">
              <Link className='breadcrumb-link' to={pageRoutes.Services}>Services</Link>
            </p>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link className='breadcrumb-link' to={pageRoutes.DevOps} onClick={scrollToTop}>Cloud & DevOps</Link>
            </p>
          </p>
          <div className='red-bg-box'></div>
        </div>

        <div className="about-our-service">
          <div className="left-section animate__animated animate__fadeInLeft animate__delay-1s">
            <h5>About Our Service</h5>
            <h2>Cloud & DevOps</h2>
            <h3>About Our Service</h3>
            <p>Navigating the digital skies with precision, Wingman Partners Cloud and DevOps engineers redefine operational excellence, making complexity a thing of the past.
              <img alt="blue-box" className='blue-box' src={images.dark_blue_box} />
              <img alt="red-box" className='red-box' src={images.red_box} />
              <div className='red-box-sq'></div>
            </p>
            <img alt="ble-box" src={images.blue_box} />
          </div>
          <div className="right-section animate__animated animate__fadeInRight animate__delay-1s">
            <LazyLoadImage
              src={images.DEVOPS_IMG}
              alt='devops'
            />
          </div>
          <img src={images.blue_box} alt="..." />
          <img src={images.circles} className='circles-DevOps' alt="..." />
        </div>

        <div className="lower-div">
          <h2>What We Offer</h2>
          <Cards cardData={cardDataDevOps} />

          <div className="teams-accreditation">
            <h2>Team’s Accreditation</h2>
            <p>Our team have proven expertise across a range of technologies.</p>
            <div className="images-teams-accreditation">
              {teamDevopsImages.map((URL, index) => (
                <LazyLoadImage
                  src={URL}
                  alt='teams-accreditation'
                />
              ))}
            </div>
          </div>

          <div className="devops-expertise">
            <h2>Our Cloud Expertise In</h2>
            <div className="devops-expertise-images">
              {devopsExpertiseImages.map((devops, index) => (
                <><div className='image-wrapper'>
                  <LazyLoadImage
                    className='hover-effect'
                    src={devops.url}
                    alt='software'
                  />
                  <div className="text-overlay">{devops.name}</div>
                </div><>
                    {(index === 0 || index === 1) &&
                      <div className='vertical-line-devops'></div>}
                  </></>
              ))}

            </div>
          </div>
        </div>
        <HomePageFooter page="" />
        <Footer />
      </div>
    </>
  )
}

export default DevOps