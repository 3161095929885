import React from "react";
import "./style.scss";
import { IMAGES } from "src/shared/utils/appConstant";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "src/components/footer";
import { Link } from "react-router-dom";
import { scrollToTop } from "src/shared/utils/appConstant";
import Button from "src/components/button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the desired effect styles
import "animate.css";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

const Leadership = () => {
  return (
    <>
      <div className="leadership-container">
        <div className="leadership-image-container image-container position-rel">
          <div className="text-container">
            <h1 className="heading drop-in">Leadership</h1>
            <p className="heading-desc display-block display-none drop-in-2">
              Wingman Partners owes its success to the dynamic leadership that
              shapes our path. Our team, comprised of visionaries, strategists,
              and industry experts, is dedicated to pioneering innovation and
              upholding unwavering commitment to our clients. Introducing the
              individuals who drive our mission.
            </p>
          </div>
          <div className="breadcrumb position-abs drop-in-2">
            <Link to={pageRoutes.Home} onClick={scrollToTop}>
              Home
            </Link>
            <span className="breadcrumb-separator"> / </span>
            <p className="fw-light">
              <Link to={pageRoutes.Leadership}>Leadership</Link>
            </p>
          </div>
          <div className="red-bg-box"></div>
        </div>
        <div className="know-us-better">
          <div className="know-us-better-heading">
            <p>Get To Know Us Better</p>
          </div>
          <div className="content-container display-none">
            <div className="content-row-right">
              <div className="col-content animate__animated animate__fadeInLeft animate__delay-1s">
                <div className="col-heading">
                  <p>Ashav Kaushik</p>
                </div>
                <div className="col-subheading">
                  <p>CEO</p>
                  <div className="kub-icon">
                    <a
                      href="https://www.linkedin.com/in/ashavkaushik"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={IMAGES.LINKDIN_ICON} alt=".." />
                    </a>
                  </div>
                </div>
                <div className="col-desc fs-24">
                  <p>
                    Meet  Ashav Kaushik, our visionary CEO and Founder of Wingman Partners, boasting diverse expertise in Application Software, Public Cloud, Embedded Software, and the Software Development Life Cycle. A skilled people manager with strong communication skills, he excels in Project Initiation, Product Definition, and Project Scheduling. From kernel to cloud, he navigates domains like Linux OS, embedded software, web, and mobility. Leading cross-functional teams, Ashav's innovation optimizes modules and delivers excellence in debugging. Beyond business, his interests in photography, gaming, and running showcase a well-rounded personality. Always abreast of market trends, he is a transformative leader in an ever-evolving tech landscape.{" "}
                  </p>
                </div>
              </div>
              <LazyLoadImage
                src={IMAGES.ASHAV_IMG}
                className="card-img-top animate__animated animate__fadeInRight animate__delay-1s"
                effect="blur"
                alt="ashav"
              />
            </div>
            <div className="content-row-left">
                <LazyLoadImage
                  src={IMAGES.NISHANT_IMG}
                  effect="blur"
                  alt="nishant"
                  className="animate__animated animate__fadeInLeft"
                />
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                offset={150}
                animateOnce={true}
              >
                <div className="col-content">
                  <div className="col-heading ">
                    <p>Nishant Kaushik</p>
                  </div>
                  <div className="col-subheading ">
                    <p>VP Engineering</p>
                    <div className="kub-icon">
                      <a
                        href="https://www.linkedin.com/in/nishant-kaushik-wingman-partners"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={IMAGES.LINKDIN_ICON} alt=".." />
                      </a>
                    </div>
                  </div>
                  <div className="col-desc">
                    <p>
                      Meet Nishant Kaushik, our Vice President Engineering, a
                      seasoned Technical Architect and Certified Scrum Master
                      with years of rich IT experience. Specializing in .NET,
                      AWS, and more, Nishant thrives on building high-quality,
                      scalable products. His diverse strengths include training,
                      Scrum mastery, and a keen focus on continuous improvement.
                      With a proven track record of successful relationships
                      with investors and senior management, Nishant is eager to
                      contribute his expertise to an organization's goals in
                      Information Technology. Connect with him for detailed
                      insights into his technical prowess and innovative
                      approach.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="content-row-right">
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                offset={150}
                animateOnce={true}
              >
                <div className="col-content">
                  <div className="col-heading">
                    <p>Garima Singh</p>
                  </div>
                  <div className="col-subheading">
                    <p>VP,Quality & Operations</p>
                    <div className="kub-icon">
                      <a
                        href="https://www.linkedin.com/in/garima-singh-o81z/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={IMAGES.LINKDIN_ICON} alt=".." />
                      </a>
                    </div>
                  </div>
                  <div className="col-desc">
                    <p>
                    Meet Garima Singh, our VP of Operations & Quality, shaping business operations with foresight. With an eye for detail, she ensures excellence in every facet of our operations. Her wealth of experience spans software testing, project management, stakeholder communication, and talent acquisition. Her prowess in translating business requirements into technical solutions positions her as the driving force behind our organization. As a seasoned software tester, Garima specializes in Mobile Testing, Web Testing, Functional Testing, and Network Testing. Her proficiency extends to Agile methodology, SDLC, and STLC, making her an invaluable asset to our team. Outside of work, Garima's interests include artistic endeavors, travel, and a deep connection with nature.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
                <LazyLoadImage
                  src={IMAGES.GARIMA_IMG}
                  effect="blur"
                  alt="garima"
                  className="animate__animated animate__fadeInRight"
                />
            </div>
            <div className="content-row-left">
                <LazyLoadImage
                  src={IMAGES.ALOK_IMG}
                  effect="blur"
                  alt="nishant"
                  className="animate__animated animate__fadeInLeft"
                />
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                offset={150}
                animateOnce={true}
              >
                <div className="col-content">
                  <div className="col-heading fs-54">
                    <p>Alok Kumar Singh</p>
                  </div>
                  <div className="col-subheading fs-32">
                    <p>VP New ventures</p>
                    <div className="kub-icon">
                      <a
                        href="https://www.linkedin.com/in/alok-kr-singh/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={IMAGES.LINKDIN_ICON} alt=".." />
                      </a>
                    </div>
                  </div>
                  <div className="col-desc">
                    <p>
                    Meet Alok Singh, Wingman Partners VP New Ventures. An adept technical maestro with extensive experience, Alok specializes in Python, Django, Flask, and Node.js, creating robust solutions from scratch. Beyond coding, he's a database virtuoso and AWS expert, seamlessly navigating its components. A seasoned architect, he has nurtured projects across diverse domains, showcasing adaptability. His approach blends technical wisdom and business strategy, architecting solutions that elevate products. A trusted guide, Alok offers consulting services, assesses technical risks, and defines Software Architecture with an unwavering commitment to excellence. In him, businesses find not just a coder but a visionary crafting solutions that transcend expectations.                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="kub-mv display-toggle kub-mv-display">
            <div className="kub-mv-display">
              <div className="kub-mv-content">
                <div className="kub-img-mv">
                  <img src={IMAGES.KUB_IMAGE_MV_1} alt="KUB-img" />
                </div>
                <div className="content-header-mv">
                  <div className="content-col">
                    <div className="content-name">
                      <p>Ashav Kaushik</p>
                    </div>
                    <div className="content-desg">
                      <p>CEO</p>
                    </div>
                  </div>
                  <div className="content-icon">
                    <a
                      href="https://www.linkedin.com/in/ashavkaushik"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={IMAGES.LINKDIN_ICON} alt=".." />
                    </a>
                  </div>
                </div>
                <div className="content-para">
                  <p>
                  Meet  Ashav Kaushik, our visionary CEO and Founder of Wingman Partners, boasting diverse expertise in Application Software, Public Cloud, Embedded Software, and the Software Development Life Cycle. A skilled people manager with strong communication skills, he excels in Project Initiation, Product Definition, and Project Scheduling. From kernel to cloud, he navigates domains like Linux OS, embedded software, web, and mobility. Leading cross-functional teams, Ashav's innovation optimizes modules and delivers excellence in debugging. Beyond business, his interests in photography, gaming, and running showcase a well-rounded personality. Always abreast of market trends, he is a transformative leader in an ever-evolving tech landscape.{" "}
                  </p>
                </div>
              </div>
              <div className="kub-mv-content">
                <div className="kub-img-mv">
                  <img src={IMAGES.KUB_IMAGE_MV_2} alt="KUB-img" />
                </div>
                <div className="content-header-mv">
                  <div className="content-col">
                    <div className="content-name">
                      <p>Nishant Kaushik</p>
                    </div>
                    <div className="content-desg">
                      <p>VP Engineering</p>
                    </div>
                  </div>
                  <div className="content-icon">
                    <a
                      href="https://www.linkedin.com/in/nishant-kaushik-wingman-partners"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={IMAGES.LINKDIN_ICON} alt=".." />
                    </a>
                  </div>
                </div>
                <div className="content-para">
                  <p>
                  Meet Nishant Kaushik, our Vice President Engineering, a seasoned Technical Architect and Certified Scrum Master with years of rich IT experience. Specializing in .NET, AWS, and more, Nishant thrives on building high-quality, scalable products. His diverse strengths include training, Scrum mastery, and a keen focus on continuous improvement. With a proven track record of successful relationships with investors and senior management, Nishant is eager to contribute his expertise to an organization's goals in Information Technology. Connect with him for detailed insights into his technical prowess and innovative approach. 
                  </p>
                </div>
              </div>
              <div className="kub-mv-content">
                <div className="kub-img-mv">
                  <img src={IMAGES.KUB_IMAGE_MV_3} alt="KUB-img" />
                </div>
                <div className="content-header-mv">
                  <div className="content-col">
                    <div className="content-name">
                      <p>Garima Singh</p>
                    </div>
                    <div className="content-desg">
                      <p>VP,Quality & Operations</p>
                    </div>
                  </div>
                  <div className="content-icon">
                    <a
                      href="https://www.linkedin.com/in/garima-singh-o81z/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={IMAGES.LINKDIN_ICON} alt=".." />
                    </a>
                  </div>
                </div>
                <div className="content-para">
                  <p>
                  Meet Garima Singh, our VP of Operations & Quality, shaping business operations with foresight. With an eye for detail, she ensures excellence in every facet of our operations. Her wealth of experience spans software testing, project management, stakeholder communication, and talent acquisition. Her prowess in translating business requirements into technical solutions positions her as the driving force behind our organization. As a seasoned software tester, Garima specializes in Mobile Testing, Web Testing, Functional Testing, and Network Testing. Her proficiency extends to Agile methodology, SDLC, and STLC, making her an invaluable asset to our team. Outside of work, Garima's interests include artistic endeavors, travel, and a deep connection with nature.
                  </p>
                </div>
              </div>
              <div className="kub-mv-content">
                <div className="kub-img-mv">
                  <img src={IMAGES.KUB_IMAGE_MV_4} alt="KUB-img" />
                </div>
                <div className="content-header-mv">
                  <div className="content-col">
                    <div className="content-name">
                      <p>Alok Kumar Singh</p>
                    </div>
                    <div className="content-desg">
                      <p>VP New ventures</p>
                    </div>
                  </div>
                  <div className="content-icon">
                    <a
                      href="https://www.linkedin.com/in/alok-kr-singh/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={IMAGES.LINKDIN_ICON} alt=".." />
                    </a>
                  </div>
                </div>
                <div className="content-para">
                  <p>
                  Meet Alok Singh, Wingman Partners VP New Ventures. An adept technical maestro with extensive experience, Alok specializes in Python, Django, Flask, and Node.js, creating robust solutions from scratch. Beyond coding, he's a database virtuoso and AWS expert, seamlessly navigating its components. A seasoned architect, he has nurtured projects across diverse domains, showcasing adaptability. His approach blends technical wisdom and business strategy, architecting solutions that elevate products. A trusted guide, Alok offers consulting services, assesses technical risks, and defines Software Architecture with an unwavering commitment to excellence. In him, businesses find not just a coder but a visionary crafting solutions that transcend expectations.  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="team">
          <div className="team-header fs-54">
            <p>Meet Our Team</p>
          </div>
          <div>
            <Link to="/team">
              <Button onClick={scrollToTop}>Team</Button>
            </Link>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Leadership;
