import React, { ReactNode, useRef, useEffect } from "react";
import "./style.scss";
import { IMAGES } from "src/shared/utils/appConstant";
import { googleMapLink } from "src/shared/utils/string";
import ContactForm from "./contactForm";
import Modal from "react-modal";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { pageRoutes } from "src/shared/utils/pageRoutes.constant";

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  buttonText?: string;
  triggerButtonRef?: React.RefObject<HTMLButtonElement>;
}

export default function MyModal(props: ModalType) {
  const modalRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        // Clicked outside the modal, close it
        props.toggle();
      }
    }

    if (props.isOpen) {
      // Add event listener when modal is open
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }; // eslint-disable-next-line
  }, [props.isOpen, props.toggle]);

  const isContactRoute = location.pathname === pageRoutes.Contact;

  const isMobileView = useMediaQuery('(max-width:767px)');
  if (isMobileView) {
    return null;
  }

  return (
    <div className="my-modal">
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.toggle}
        contentLabel="Modal-box"
        className={`style.custom-modal`}
        style={{
          overlay: {
            position: 'fixed',
            zIndex: 400,
            top: '0',
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.25)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            zIndex: 500,
            background: 'none',
            width: '80%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'relative',
            margin: '0 auto',
            border: 'none',
            borderRadius: '0',
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            outline: 'none',
          }
        }}
      >
        <div className="modal-box" ref={modalRef}>
          {props.children}
          <div className="contact-container">
            <div className="row contact-row">
              <div className="col-md-6 contact-col">
                <div className="contact-header">
                  <p>Contact Us</p>
                </div>
                <div className="contact-sub-header">
                  <p>
                    Transforming Ideas <br />
                    Into Reality
                  </p>
                </div>
                <div className="contact-img">
                  <img src={IMAGES.CONTACT_IMG} alt="contact" />
                </div>
                <div className="contact-info">
                  <a
                    href={googleMapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IMAGES.LOCATION_ICON} alt="location" />
                  </a>{" "}
                  <p>
                  Plot No. B-04, First Floor, Tower 2 <br />
                  Sector 63, Noida, India
                  </p>
                </div>
              </div>
              <div className="vertical-line"></div>
              <div className="col-md-6 contact-col-details">
                {!isContactRoute && (
                  <div className="close-icon" onClick={props.toggle}>
                    <img src={IMAGES.MODAL_CROSS} alt="modal-cross" />
                  </div>
                )}
                <div className="contact-sub-header">
                  <p>Share Your Details</p>
                </div>
                <ContactForm />
                <p className="or-text">Or</p>
                <div className="email-link">
                  <p>
                    Drop us an Email at{" "}
                    <a
                      href="mailto:connect@wingmanpartners.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="contact-link"
                    >
                      connect@wingmanpartners.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
