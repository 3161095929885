import React, { useState } from "react";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.css";
import {
  images,
  scrollToTop,
  socialMediaImg,
} from "src/shared/utils/appConstant";
import { footerData } from "src/shared/utils/string";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleToggle = (id: string) => {
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem === id ? null : id
    );
  };

  return (
    <>
      <footer className="footer">
        {/* // web footer */}
        <div className="upper-container">
          <div className="left-part">
            {footerData.map((footerData, index) => (
              <div className="column" key={index}>
                {footerData.headingURL ? (
                  <Link
                    to={footerData.headingURL}
                    onClick={scrollToTop}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <h5>{footerData.heading}</h5>
                  </Link>
                ) : (
                  <h5>{footerData.heading}</h5>
                )}
                {footerData.colData.map((value, subIndex) => (
                  <p key={subIndex}>
                    {footerData.routeLink ? (
                      <Link
                        to={footerData.routeLink[subIndex]}
                        onClick={scrollToTop}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {value}
                      </Link>
                    ) : (
                      <span>{value}</span>
                    )}
                  </p>
                ))}
              </div>
            ))}
          </div>
          {/* mobile footer */}
          <div className="nav mob-footer" role="navigation">
            <ul className="nav-list">
              {footerData.map((dataFooter, index) => (
                <li key={index}>
                  <input
                    id={"group-" + (index + 1)}
                    type="radio"
                    name="nav-group"
                    checked={selectedItem === "group-" + (index + 1)}
                    onClick={() => handleToggle("group-" + (index + 1))}
                    hidden
                  />
                  <label htmlFor={"group-" + (index + 1)}>
                    {/* <span>{dataFooter.heading}</span> */}
                    {dataFooter.headingURL ? (
                      <Link
                        to={dataFooter.headingURL}
                        onClick={scrollToTop}
                        className="head-style"
                      >
                        <span>{dataFooter.heading}</span>
                      </Link>
                    ) : (
                      <span>{dataFooter.heading}</span>
                    )}
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="arrows-footer"
                    />
                  </label>
                  <ul className="group-list">
                    {dataFooter.colData.map((value, subIndex) => (
                      <li key={subIndex}>
                        {dataFooter.routeLink ? (
                          <Link
                            to={dataFooter.routeLink[subIndex]}
                            onClick={scrollToTop}
                            className="routelink-style"
                          >
                            {value}
                          </Link>
                        ) : (
                          <span>{value}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div className="column right-part">
            <h5>Connect With Us</h5>
            <div className="social-media">
              {socialMediaImg.map((socialMedia, index) => (
                <a
                  key={index}
                  href={socialMedia.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="social-link-logo"
                    alt="Social Media"
                    src={socialMedia.url}
                  />
                </a>
              ))}
            </div>
            <img className="logo" alt="Logo" src={images.logo} />
          </div>
        </div>
        <div className="address-contact-container">
          <div className="location-content">
            <img className = "location-pin" alt="Logo" src={images.location_pin} />
            <p>
              {" "}
              Plot No. B-04, First Floor, Tower 2, Sector 63, Noida, India{" "}
            </p>
          </div>
        </div>
        <div className="trademark">
          <p>All rights reserved @2024 Wingman Partners (WMP Solutions LLP)</p>
          {/* <p>License & Attributions</p> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
