// routes.js
import SoftwareEngineering from 'src/views/SoftwareEngineering';
import MobileAppDev from 'src/views/SoftwareEngineering/components/MobileAppDev';
import POC from 'src/views/SoftwareEngineering/components/POC';
import ProductReEngg from 'src/views/SoftwareEngineering/components/ProductReEngg';
import SoftwareIntegration from 'src/views/SoftwareEngineering/components/SoftwareIntegration';
import WebAppDev from 'src/views/SoftwareEngineering/components/WebAppDev';
import Home from 'src/views/home';
import AboutUs from 'src/views/about_us';
import Team from 'src/views/team';
import Leadership from 'src/views/leadership';
import Blog from 'src/views/blog';
import Awards from 'src/views/awards';
import LifeAtWmp from 'src/views/life_at_wmp';
import Services from 'src/views/home/components/services';
import DevOps from 'src/views/DevOps';
import CloudInfra from 'src/views/DevOps/components/CloudInfra';
import CloudMigration from 'src/views/DevOps/components/CloudMigration';
import DevOpsAuto from 'src/views/DevOps/components/DevOpsAutomation';
import DevopsOperations from 'src/views/DevOps/components/DevOpsOperations';
import Security from 'src/views/DevOps/components/Security';
import QA from 'src/views/QA';
import TestingAndAutomation from 'src/views/QA/components/TestingAndAutomation';
import ApiDrivenTesting from 'src/views/QA/components/ApiDrivenTesting';
import PerformanceTesting from 'src/views/QA/components/PerformanceTesting';
import PenetrationTesting from 'src/views/QA/components/PenetrationTesting';
import Operations from 'src/views/Operations';
import TechManagement from 'src/views/Operations/components/TechWriting';
import ProjectManagement from 'src/views/Operations/components/ProjectManagement';
import ProductManagement from 'src/views/Operations/components/ProductManagement';
import Support from 'src/views/Operations/components/Support';
import Designing from 'src/views/UI';
import WebDesigning from 'src/views/UI/components/WebDesigning';
import AppDesigning from 'src/views/UI/components/AppDesigning';
import InfoArchitectureDesign from 'src/views/UI/components/InfoArchitectureDesign';
import SuccessStories from 'src/views/SuccessStories';
import NetworkManagement from 'src/views/SuccessStories/components/NetworkManagement';
import Technology from 'src/views/SuccessStories/components/Technology';
import { pageRoutes } from './pageRoutes.constant';
import Solutions from 'src/views/solutions';
import Trakwa from'src/views/solutions/Components/Trakwa';
import CXOTulz from 'src/views/solutions/Components/CXOTulz';
import Qufree from 'src/views/solutions/Components/Qufree';
import Hospitality from 'src/views/SuccessStories/components/Hospitality';
import Healthcare from 'src/views/SuccessStories/components/Healthcare';
import Transportation from 'src/views/SuccessStories/components/Transportation';
import KitchenDining from 'src/views/SuccessStories/components/KitchenDining';
import TechSpaces from 'src/views/SuccessStories/components/TechSpaces';
import EmpCommunity from 'src/views/SuccessStories/components/EmpCommunity';
import NonGovOrg from 'src/views/SuccessStories/components/NonGovtOrg';


export const routes = [
  {
    path: pageRoutes.Home,
    exact: true,
    component: Home,
  },
  {
    path: pageRoutes.SoftwareEngineering,
    exact: true,
    component: SoftwareEngineering,
  },
  {
    path: pageRoutes.WebAppDev,
    exact: true,
    component: WebAppDev,
  },
  {
    path: pageRoutes.MobileAppDev,
    exact: true,
    component: MobileAppDev,
  },
  {
    path: pageRoutes.ProductReEngg,
    exact: true,
    component: ProductReEngg,
  },
  {
    path: pageRoutes.SoftwareIntegration,
    exact: true,
    component: SoftwareIntegration,
  },
  {
    path: pageRoutes.POC,
    exact: true,
    component: POC,
  },
  {
    path: pageRoutes.AboutUs,
    exact: true,
    component: AboutUs,
  },
  {
    path: pageRoutes.Team,
    exact: true,
    component: Team,
  },
  {
    path: pageRoutes.Leadership,
    exact: true,
    component: Leadership,
  },
  {
    path: pageRoutes.Blog,
    exact: true,
    component: Blog,
  },
  {
    path: pageRoutes.Awards,
    exact: true,
    component: Awards,
  },
  {
  path: pageRoutes.LifeAtWmp,
  exact: true,
  component: LifeAtWmp,
  },
  {
    path: pageRoutes.Services,
    exact: true,
    component: Services,
  },
  {
    path: pageRoutes.DevOps,
    exact: true,
    component: DevOps,
  },
  {
    path: pageRoutes.CloudInfra,
    exact: true,
    component: CloudInfra,
  },
  {
    path: pageRoutes.CloudMigration,
    exact: true,
    component: CloudMigration,
  },
  {
    path: pageRoutes.DevOpsAuto,
    exact: true,
    component: DevOpsAuto,
  },
  {
    path: pageRoutes.DevopsOperations,
    exact: true,
    component: DevopsOperations,
  },
  {
    path: pageRoutes.Security,
    exact: true,
    component: Security,
  },
  {  
    path: pageRoutes.QA,
    exact: true,
    component: QA,
  },
  {
    path: pageRoutes.TestingAndAutomation,
    exact: true,
    component: TestingAndAutomation,
  },
  {
    path: pageRoutes.ApiDrivenTesting,
    exact: true,
    component: ApiDrivenTesting,
  },
  {
    path: pageRoutes.PerformanceTesting,
    exact: true,
    component: PerformanceTesting,
  },
  {
    path: pageRoutes.PenetrationTesting,
    exact: true,
    component: PenetrationTesting,
  },
  {
    path: pageRoutes.Operations,
    exact: true,
    component: Operations,
  },
  {
    path: pageRoutes.TechManagement,
    exact: true,
    component: TechManagement,
  },
  {
    path: pageRoutes.ProjectManagement,
    exact: true,
    component: ProjectManagement,
  },
  {
    path: pageRoutes.ProductManagement,
    exact: true,
    component: ProductManagement,
  },
  {
    path: pageRoutes.Support,
    exact: true,
    component: Support,
  },
  {
    path: pageRoutes.Designing,
    exact: true,
    component: Designing,
  },
  {
    path: pageRoutes.WebDesigning,
    exact: true,
    component: WebDesigning,
  },
  {
    path: pageRoutes.AppDesigning,
    exact: true,
    component: AppDesigning,
  },
  {
    path: pageRoutes.InfoArchitectureDesign,
    exact: true,
    component: InfoArchitectureDesign,
  },
  {  
    path: pageRoutes.SuccessStories,
    exact: true,
    component: SuccessStories,
  },
  {  
    path: pageRoutes.NetworkManagement,
    exact: true,
    component: NetworkManagement,
  },
  {  
    path: pageRoutes.Technology,
    exact: true,
    component: Technology,
  },
  {  
    path: pageRoutes.Solutions,
    exact: true,
    component: Solutions,
  },
  {  
    path: pageRoutes.Trakwa,
    exact: true,
    component: Trakwa,
  },
  {  
    path: pageRoutes.CXOTulz,
    exact: true,
    component: CXOTulz,
  },
  {  
    path: pageRoutes.Qufree,
    exact: true,
    component: Qufree,
  },
  {
  path: pageRoutes.Hospitality,
  exact: true,
  component: Hospitality,
  },
  {
  path: pageRoutes.Healthcare,
  exact: true,
  component: Healthcare,
  },
  {
    path: pageRoutes.Transportation,
    exact: true,
    component: Transportation,
  },
  {
    path: pageRoutes.TechSpaces,
    exact: true,
    component: TechSpaces,
  },
  {
    path: pageRoutes.EmpCommunity,
    exact: true,
    component: EmpCommunity,
  },
  {
    path: pageRoutes.KitchenDining,
    exact: true,
    component: KitchenDining,
  },
  {
    path: pageRoutes.NonGovOrg,
    exact: true,
    component: NonGovOrg,
  },

];
