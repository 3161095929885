import React from 'react';
import './style.scss';
import CardsServices from '../CardsServices';
import {cardDataWebAppDev } from 'src/shared/utils/string';
import FooterServices from '../FooterServices';
import Footer from 'src/components/footer';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'src/shared/utils/appConstant';
import { pageRoutes } from 'src/shared/utils/pageRoutes.constant';

const WebAppDev = () => {


  return (
    <div className='page-container-WebApp'>
        <div className='image-container'>
          <div className='text-container drop-in-2'>
          <h1 className='heading'>Web App Development</h1>
          </div>
            <p className='breadcrumb position-abs drop-in-3'>
              <Link className='breadcrumb-link' to = {pageRoutes.Home} onClick={scrollToTop}>Home </Link>
              <span className="breadcrumb-separator"> / </span>
              <Link className='breadcrumb-link' to = {pageRoutes.Services}>Services</Link>
              <span className="breadcrumb-separator"> / </span>
              <Link className='breadcrumb-link' to = {pageRoutes.SoftwareEngineering} onClick={scrollToTop}>Software Engineering</Link>
              <span className="breadcrumb-separator"> / </span>
              <Link className='breadcrumb-link fw-light' to = {pageRoutes.WebAppDev} onClick={scrollToTop}>Web App Development</Link>
              </p>
            <div className='red-bg-box'></div>
        </div>

        <CardsServices cardDataServices = {cardDataWebAppDev} />
        <FooterServices />
        <Footer />
    </div>
  )
}

export default WebAppDev